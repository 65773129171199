import { staticFiles } from "../../../shared";
import { Logo } from "../../../shared/components/Logo";
import { Link } from "react-router-dom";

const FooterCol: React.FC<{ children: React.ReactNode; hidden?: boolean }> = ({
  children,
  hidden,
}) => {
  const hiddenColClass = "w-1/3 grow flex flex-col justify-center px-[20px] items-center";
  const colClass = "w-1/3 grow flex flex-col justify-center px-[20px] items-center";
  return <div className={hidden ? hiddenColClass : colClass}>{children}</div>;
};

const fakeDestinationsLink = [
  "Return Policy",
  "Bullet list goes here",
  "Linked Text Goes here",
  "Linked Text Goes here",
  "Linked Text Goes here",
  "Linked Text Goes here",
  "Linked Text Goes here",
  "Linked Text Goes here",
  "Linked Text Goes here",
];

export const Footer = () => {
  return (
    <div className="h-[350px] w-full bg-[#262424] flex flex-col items-center">
      <div className="flex justify-between max-w-[1300px] h-[80%] gap-40">
        <FooterCol>
          <div className="min-h-[200px] flex flex-col justify-center ">
            <div className="flex justify-center">
              <Logo whiteText />
            </div>

            <div className="flex justify-center items-center mt-10 text-white font-poppins text-xs w-[200px]">
              <span className="inline-block">
                #1 customer service trave agency in New York. Explore New York city with TAMICE Your
                satisfaction is our high priority
              </span>
            </div>
          </div>
        </FooterCol>
        <FooterCol>
          <div className="min-h-[200px] hidden sm:block">
            <span className="font-poppins font-medium text-sm text-white mb-5">
              TOP DESTINATIONS
            </span>
            <div className="flex flex-col">
              {fakeDestinationsLink.map((item, idx) => (
                <div
                  key={item + idx}
                  className="font-poppins text-xs text-white hover:underline hover:cursor-pointer"
                >
                  {item === "Return Policy" ? (
                    <Link to="/return-policy">• {item}</Link>
                  ) : (
                    <span>• {item}</span>
                  )}
                </div>
              ))}
            </div>
          </div>
        </FooterCol>
        <FooterCol>
          <div className="min-h-[200px]">
            <div className="font-poppins font-medium text-sm text-white">CONTACT INFO</div>
            <div className="font-poppins font-normal text-xs text-white">
              Address: 151 West 46th Street, Suite 1002, New York, NY 10036
            </div>
            <div className="mt-3 font-poppins font-normal text-xs text-white">Phone:</div>
            <div className="font-poppins font-normal text-xs text-white">646-684-4848</div>
            <div className="font-poppins font-normal text-xs text-white">1800-6991</div>
            <div className="mt-3 font-poppins font-normal text-xs text-white">
              Contact: service@tamice.com
            </div>
            <div className="mt-3 flex">
              <button onClick={() => {}}>
                <img src={staticFiles.icons.facebook} alt="fb" />
              </button>
              <button className="ml-2" onClick={() => {}}>
                <img src={staticFiles.icons.instagram} alt="ig" />
              </button>
              <button className="ml-2" onClick={() => {}}>
                <img src={staticFiles.icons.blog} alt="blog" />
              </button>
            </div>
          </div>
        </FooterCol>
      </div>
      <div className="flex justify-center items-center h-[20%] bg-black w-full">
        <span className="font-poppins text-[#A4A5A8] font-normal text -sm">
          COPYRIGHT 2022 TAMICE INC, ALL RIGHT RESERVED
        </span>
      </div>
    </div>
  );
};
