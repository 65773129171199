import { useNavigate } from "react-router-dom";
import { staticFiles } from "../../../shared";
import { MainButton } from "../../../shared/components/Buttons";
import { SpaceY } from "../../../shared/components/Utils";
import { useState } from "react";
import { musicState } from "../../../App"

export type ShowCardProps = {
  product_id  : string;
  product_code: string;
  price: string;
  product_date_time: string;
  regular_price: number;
  description: string;
  sell_price : string;
  include : string;
};

export const ShowCard: React.FC<ShowCardProps> = ({
  product_id,
  product_code,
  price,
  product_date_time,
  regular_price,
  description,
  sell_price,
  include,
}) => {
  const navigate = useNavigate();
  const [ selected, setSelected] = useState(false);
  const handleStateChange = () =>{
    
    localStorage.setItem("music", product_id);
    localStorage.setItem("musicData", JSON.stringify({
      description,
      product_date_time,
      regular_price,
      sell_price,
      price
    }));
    setSelectedMusic({music_id: product_id});
  }
  const  [ selectedMusic, setSelectedMusic ] = musicState.useState();
  return (
    <>
      
      
        {/* <div className="flex items-center">
          <img className="h-full object-cover" src={image} />
        </div>
        <SpaceY />
        <div className="font-poppins w-full flex flex-col">
          <span className="font-bold text-dark">{title}</span>
          <span className="font-bold text-dark">{subTitle}</span>
          <SpaceY />
          <span className="text-sm text-darkGray w-[70%]">{description}</span>
        </div>
        <SpaceY />
        <div className=" w-full flex flex-col justify-center items-center gap-y-7 p-2">
          <div className="flex items-center text-center">
            <span className="font-poppins text-sm text-gray mr-2">From</span>
            <span className="font-poppins font-medium text-dark">
              ${priceStart}
            </span>
          </div>
          <MainButton
            text="VIEW DETAILS"
            onClick={() => navigate(`/show-detail/${id}`)}
            containerClassName="w-full text-xs"
          />
        </div>
      </div> */}
      <div className="hidden sm:flex w-full bg-white gap-x-4 py-4 items-center text-dark flex " onClick={handleStateChange} style={{cursor:"pointer"}}>
        <div className="w-1/12 flex items-center justify-center">
          <img  width={20} src={
            selectedMusic.music_id == product_id
            ? staticFiles.icons.green_check
            : staticFiles.icons.black_check} />
        </div>
        <div className="font-poppins w-7/12 flex-col">
          <span className="text-[#5D5D5F]">{description}</span>
        </div>
        <div className="font-poppins w-3/12 flex flex-col">
          <span className="text-center text-[#5D5D5F]" style={{textDecorationLine: 'line-through'}}>${regular_price || "0"}</span>
        </div>
        <div className="border-l border-gray w-2/12 flex-col gap-y-7 p-2">
          <div className="flex-col text-center">
            {/* <span className="font-poppins text-sm text-gray mr-2">From</span> */}
            <span className="font-poppins text-[#5D5D5F]">
              ${price || "0"}
            </span>
          </div>
          {/* <MainButton
            text="VIEW DETAILS"
            onClick={() => navigate(`/show-detail/${id}`)}
            containerClassName="min-w-[130px] text-xs"
          /> */}
        </div>
        
        </div>
    </>
  );
};
