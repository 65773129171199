import { useGetShowTickets, GetMusicalData, useGetMusicals } from "../../shared/hooks";
import { ShowBuyDetail } from "./components/ShowBuyDetail";
import { ShowCard } from "./components/ShowCard";
import { musicState } from "../../App";
import { useEffect, useState } from "react";
import { MainButton } from "../../shared/components/Buttons";
import { SpaceY } from "../../shared/components/Utils";
import { useNavigate } from "react-router-dom";

export const MusicalView = () => {
  const { tickets } = useGetMusicals({
    category: 57,
    guideFilter: true,
  });

  const fakeData = {
    image: "https://travelapi.unifyq.com/storage/images/cYCzQK9Br9HCLGDEAYVdSF9Ag9D2rdERUkwmedkv.png",
    en: "Lion King",
    kr: "KR_Lion King",
    description: "뉴욕의 명소 브로드웨어. - 연일 매진행렬을 이어오는 가장 인기 있는 뮤지컬",
  };

  console.log("Musical", tickets);
  const navigate = useNavigate();
  // console.log("musicalData", musicalData);

  return (
    <div className="flex w-full gap-x-3 justify-center">
      <div className="flex flex-col gap-y-4 w-full max-w-[1300px]">
        {tickets?.map((data) => (
          <div
            className="flex flex-col justify-center items-center md:flex-row w-full p-8 bg-white justify-between rounded-b-3xl"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/musicals_view/${data.id}`)}
          >
            <div className=" flex items-center justify-center relative w-full">
              <img className="object-cover h-full max-w-[200px]" src={data.image} />
            </div>
            <div className="flex flex-col font-poppins gap-y-3 w-full justify-center items-center my-8">
              <span className="font-bold text-dark w-full text-[#5D5D5F]  flex justify-center" style={{ fontSize: "30px" }}>
                {data.name}
              </span>
              <span className="font-bold text-dark w-full text-[#5D5D5F] flex justify-center " style={{ fontSize: "30px" }}>
                [{data.kr_name}]
              </span>
              <div className="flex justify-center items-center w-full">
         
                  <span className="text-sm text-[#5D5D5F] ">{fakeData.description}</span>
          
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
