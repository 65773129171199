import { Controller, useForm } from "react-hook-form";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { poster, posterWithoutToken } from "../../shared";
import { useAuth } from "../../shared/hooks";
import { MainButton } from "../../shared/components/Buttons";
import { MainInput } from "../../shared/components/Inputs";
import { SpaceY } from "../../shared/components/Utils";
import { ForgotPasswordValidator } from "./utils/validations";

const resolver = classValidatorResolver(ForgotPasswordValidator);

export const ForgotPasswordView = () => {
  const { authToken } = useAuth();
  const token = localStorage.getItem("authToken");
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
    resolver,
  });

  const { mutateAsync: onSendEmail } = useMutation(posterWithoutToken(`/forgot-password`, "POST"));

  const onSubmit = async (data: { email: string }) => {
    console.log(data);
    if (data.email == "")
      return setError("email", {
        message: "Please input your email",
      });

    await onSendEmail({ email: data.email })
      .then((res) => {
        console.log(res);
        toast.success("Email sent successfully");
      })
      .catch((error) => {
        console.log("error:", error);
        toast.error(error.response.data.error);
      })
      .finally(() => {
        // alert("finally")
      });
  };

  return (
    <div className="flex justify-center">
      <div className="min-h-[600px] w-[300px] flex flex-col  items-center pt-[60px]">
        {/* <SpaceY /> */}
        <span className="text-2xl font-bold font-volkhov text-dark min min-w-[350px]">Forgot Password</span>
        <SpaceY /> <SpaceY />
        <div className="text-sm font-poppins text-left w-full">
          <span>Please enter your email address.</span>
        </div>
        <SpaceY />
        <Controller
          name="email"
          rules={{ required: true }}
          control={control}
          render={({ field }) => <MainInput value={field.value} error={errors.email?.message} placeholder="Email" onChange={(text) => field.onChange(text)} />}
        />
        <SpaceY />
        <MainButton disabled={Boolean(Object.entries(errors).length)} onClick={handleSubmit(onSubmit)} text="Reset password" />
      </div>
    </div>
  );
};
