import { useGetShowTickets, GetMusicalData, useGetMusicals } from "../../shared/hooks";
import { ShowBuyDetail } from "./components/ShowBuyDetail";
import { ShowCard } from "./components/ShowCard";
import { musicState } from "../../App";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const MusicalsAndShowsView = () => {
  // const { tickets } = useGetShowTickets({
  //   category: 1,
  // });
  const { musicalData } = GetMusicalData();
  const { date, time } = useParams();
  const [dataList, setDataList] = useState([{}]);
  const [dateValue, setDateValue] = useState("");
  console.log("result_date", date);
  console.log("result_time", time);
  const filterMusicalData = () => {
    if (musicalData && date && time) {
      const selectedDate = new Date(date);
      const formattedDate = selectedDate.getFullYear() + "/" + (selectedDate.getMonth() + 1).toString().padStart(2, "0") + "/" + selectedDate.getDate().toString().padStart(2, "0");
      const changeDate = formattedDate.split("/");
      setDateValue(changeDate[1] + "/" + changeDate[2] + "/" + changeDate[0]);

      const filteredData = musicalData.filter((data: any) => {
        const dataDate = new Date(data.product_date);
        const dataFormattedDate = dataDate.getFullYear() + "/" + (dataDate.getMonth() + 1).toString().padStart(2, "0") + "/" + dataDate.getDate().toString().padStart(2, "0");
        return dataFormattedDate === formattedDate && time === data.product_time;
      });

      setDataList(filteredData);
    } else {
      // Handle the case when musicalData, date, or time are not available
      setDataList([]);
    }
  };

  useEffect(() => {
    filterMusicalData();
  }, [date, time]);

  // const formattedDate =
  // selectedDate.getFullYear() +
  // '/' +
  // (selectedDate.getMonth() + 1).toString().padStart(2, '0') +
  // '/' +
  // selectedDate.getDate().toString().padStart(2, '0');

  const [selectedMusic, setSelectedMusic] = musicState.useState();
  useEffect(() => {
    setSelectedMusic({ music_id: "" });
  }, []);

  const { tickets } = useGetMusicals({
    category: 57,
    guideFilter: true,
  });

  // console.log("Musical", tickets);

  console.log("musicalData", musicalData);
  const include = localStorage.getItem("music");
  return (
    <div className="flex w-full gap-x-3 justify-center">
      <div className="flex flex-col w-full md:w-2/3 gap-y-8">
        <div className="flex flex-col w-full bg-white gap-x-4 py-4 border-style">
          <div className="flex w-full item-left">
            <p className="font-bold" style={{ fontSize: 30, margin: 20 }}>
              The Lion King[라이온 킹]
            </p>
          </div>
          <p className="ml-5">
            {dateValue} , {time}
          </p>
          <div className="hidden sm:flex w-full bg-white gap-x-4 items-center text-dark flex font-bold">
            <div className="w-1/12 "></div>
            <div className="font-poppins w-8/12 flex-col ml-12">
              <span className="text-[#5D5D5F]">Seating Location</span>
            </div>
            <div className="font-poppins w-3/12 flex flex-col">
              <span className="text-[#5D5D5F]">Window Price</span>
            </div>
            <div className="w-2/12 flex-colgap-y-7 p-2">
              <div className="flex-col text-center">
                <span className="font-poppins text-[#5D5D5F]">unifyq Price</span>
              </div>
            </div>
          </div>
          {dataList?.map((item: any, index: number) => (
            <ShowCard key={index} {...item} state={selectedMusic.music_id} />
          ))}
        </div>
      </div>
      <div className="md:w-1/3 md:block font-poppins w-full mt-8 md:mt-0">
        <ShowBuyDetail />
      </div>
    </div>
  );
};
