import { useEffect, useState } from "react";
import { staticFiles } from "../../../shared";
import { SelectInput } from "../../../shared/components/Inputs";
import { SpaceY } from "../../../shared/components/Utils";
import { Calendar } from "../../../shared/components/Calendar";
import { MainInput } from "../../../shared/components/Inputs";
import { MainButton, SecondaryButton } from "../../../shared/components/Buttons";
import moment from "moment";
import { Divider } from "@material-ui/core";
import { musicState } from "../../../App";
import { CartItem, cartState } from "../../../App";

const filterFakeData = {
  value: "0.00",
  location: "Seating location",
  date: "2023-07-23",
  price: 50,
  totalPrice: 100,
};

export const ShowBuyDetail = () => {
  const [filterCounter, setFilterCounter] = useState(1);
  const [selectInputOne, setSelectInputOne] = useState("");
  const [musicData, setMusicData] = musicState.useState();
  const [data, setData] = useState<any>({});
  const [cart, setCart] = cartState.useState();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  useEffect(() => {
    const musicDataString = localStorage.getItem("musicData");
    let storageData;
    try {
      // Attempt to parse the data if it exists and is not empty or null
      storageData = musicDataString ? JSON.parse(musicDataString) : null;
    } catch (error) {
      // Handle parsing errors (e.g., invalid JSON)
      console.error("Error parsing data from localStorage:", error);
      // You can set a default value if needed
      // storageData = someDefaultValue;
    }
    console.log("data", storageData);

    let location = "";
    let dateTime = "";
    let regular_price = 0;
    let sell_price = 0;
    let total_price = 0;
    let price = 0;
    let price_total = 0;

    if (musicData.music_id !== "" && musicData.music_id) {
      location = storageData.description || "";
      dateTime = storageData.product_date_time || "";
      regular_price = storageData.regular_price || 0;
      sell_price = storageData.sell_price || 0;
      total_price = storageData.regular_price * filterCounter || 0;
      price = storageData.price;
      price_total = storageData.price * filterCounter || 0;
    }

    setData({ location, dateTime, regular_price, sell_price, total_price, price, price_total });
    // setFilterCounter(1);
    console.log("musicId", musicData.music_id);
    console.log(data);
  }, [musicData, filterCounter]);
  // useEffect(()=>{
  //   data.total_price = 100 * filterCounter;
  //   // setData(data);
  //   console.log("total", data.total_price);
  //   console.log(filterCounter)
  // },[filterCounter]);
  const handleAddToTheCart = () => {
    const childInfo: CartItem[] = [...cart.childInfo];
    const adultInfo: CartItem[] = [...cart.adultInfo];

    // for (const el of selectedItems) {
    // const ticketData = musicData?.find((item) => item.id === el);

    // if (selectInputTwo === "0") {
    adultInfo.push({
      name: musicData?.music_id || "",
      price: data.price || "",
      quantity: filterCounter,
      subtotal: (data.price || 0) * filterCounter,
      addition: 0,
      cartId: musicData?.music_id || "",
      reservation_id: null,
      item_id: null,
      adult_child_type: "Adult",
    });
    // } else if (selectInputTwo === "1") {
    // childInfo.push({
    //   name: ticketData?.name || "",
    //   price: ticketData?.childPrice || 0,
    //   quantity: filterCounter,
    //   subtotal: (ticketData?.childPrice || 0) * filterCounter,
    //   addition: 0,
    // });
    // }
    // }
    localStorage.setItem(
      "CART_DATA",
      JSON.stringify({
        adultInfo,
        childInfo,
      })
    );
    setCart({
      adultInfo,
      childInfo,
    });
  };

  return (
    <div className="flex flex-col items-center pb-8 bg-white max-h-fit">
      <div className="flex flex-row items-center w-full bg-white justify-center">
        <img width={23} className="mr-5" src={staticFiles.icons.shopping_bag} />
        <span className="py-8 font-bold font-poppins text-[#5D5D5F]">티켓구입 (Ticket)</span>
      </div>
      <div className="p-5 bg-blue flex justify-center items-center text-white font-medium text-xl w-full">
        <img width={30} className="mr-5" src={staticFiles.icons.tag_white} />
        <span style={{ fontSize: "30px" }}>${data.price_total}</span>
      </div>
      <div className="w-[100%] flex flex-col px-8 gap-4 mt-4 justify-center">
        <div className="flex justify-center w-full">
          <MainInput value={data.location} error={""} placeholder="Location" onChange={(text) => {}} />
        </div>
        <div className="flex justify-center w-full">
          <MainInput value={data.dateTime} error={""} placeholder="Date and Time" onChange={(text) => {}} />
        </div>

        <div className="flex w-full">
          <span className="flex w-1/2 justify-center items-center h-[50px]">Quantity</span>
          <div className="flex w-1/2 items-center">
            <div className="flex items-center justify-between w-full px-[15%]">
              <img className="cursor-pointer" width={20} src={staticFiles.icons.decrement} onClick={() => setFilterCounter((prev) => (prev > 0 ? prev - 1 : prev))} />
              {filterCounter}
              <img className="cursor-pointer" width={20} src={staticFiles.icons.increment} onClick={() => setFilterCounter((prev) => prev + 1)} />
            </div>
          </div>
        </div>

        <div className="flex w-full gap-x-1">
          <MainButton onClick={() => handleAddToTheCart()} text="Add to the cart" />
          <SecondaryButton onClick={() => {}} text="Reset" />
        </div>
      </div>
    </div>
  );
};
