import { GetMusicalData, useGetTicket } from "../../shared/hooks";
import { staticFiles } from "../../shared";
import { useEffect, useState } from "react";
import { MainButton } from "../../shared/components/Buttons";
import { SpaceY } from "../../shared/components/Utils";
import { SelectInput } from "../../shared/components/Inputs";
import { Calendar } from "react-calendar";
import { useNavigate } from "react-router-dom";
import "./MusicalCalendar.css";

export const MusicalCalendar = () => {
  const { ticket } = useGetTicket();
  const [price, setPrice] = useState(0);
  const [date, setDate] = useState(new Date());
  const { musicalData } = GetMusicalData();
  const [dateList, setDateList] = useState([]);
  const [timeList, setTimeList] = useState<{ value: string; text: string }[]>([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    if (musicalData) {
      const extractedDates = musicalData?.map((data: any) => data.product_date);
      setDateList(extractedDates);
    }
  }, [musicalData]);

  const handleDateClick = (date: any, selectedTimeFromCalendar?: string) => {
    const selectedDate = new Date(date);
    const formattedDate =  (selectedDate.getMonth() + 1).toString().padStart(2, "0") + "/" + selectedDate.getDate().toString().padStart(2, "0") + "/" + selectedDate.getFullYear() ;

    const filteredData = musicalData.filter((data: any) => {
      const dataDate = new Date(data.product_date);
      const dataFormattedDate = dataDate.getFullYear() + "/" + (dataDate.getMonth() + 1).toString().padStart(2, "0") + "/" + dataDate.getDate().toString().padStart(2, "0");
      return dataFormattedDate === formattedDate;
    });

    console.log("date", formattedDate);
    console.log("result", filteredData);

    // Use a Set to keep track of unique values based on product_id
    const uniqueProductIds = new Set();

    const updatedTimeList = [];

    for (let i = 0; i < filteredData.length; i++) {
      const productData = filteredData[i];
      const productId = productData.product_time;

      // Check if the product_id is unique
      if (!uniqueProductIds.has(productId)) {
        uniqueProductIds.add(productId);
        updatedTimeList.push({
          value: productId,
          text: productData.product_time,
        });
      }
    }

    setTimeList(updatedTimeList);
    setSelectedDate(formattedDate);
    setDate(date);

    if (selectedTimeFromCalendar) {
      setShowConfirm(true);
    }
  };

  const handleConfirm = () => {
    setShowConfirm(false);
    navigate(`/musicals-and-shows/${date}/${selectedTime}`);
  };

  const handleCancel = () => {
    setShowConfirm(false);
    setSelectedTime(""); // Reset the selected time if user cancels
  };

  const navigate = useNavigate();
  const isDateAvailable = (date: any) => {
    const availableDates = dateList.map((dateStr) => new Date(dateStr));
    return availableDates.some((availableDate) => {
      return date.getDate() === availableDate.getDate() && date.getMonth() === availableDate.getMonth() && date.getFullYear() === availableDate.getFullYear();
    });
  };
  const isDateClicked = (dateValue: any) => {
    return date && dateValue.getDate() === date.getDate() && dateValue.getMonth() === date.getMonth() && dateValue.getFullYear() === date.getFullYear();
  };

  const [dateToTimesMap, setDateToTimesMap] = useState<{ [key: string]: string[] }>({});

  useEffect(() => {
    if (musicalData) {
      const extractedDates = musicalData?.map((data: any) => data.product_date);
      setDateList(extractedDates);

      let tempDateToTimesMap: { [key: string]: string[] } = {};
      musicalData.forEach((data: any) => {
        const formattedDate = new Date(data.product_date).toDateString();

        if (!tempDateToTimesMap[formattedDate]) {
          tempDateToTimesMap[formattedDate] = [];
        }

        if (!tempDateToTimesMap[formattedDate].includes(data.product_time)) {
          tempDateToTimesMap[formattedDate].push(data.product_time);
        }
      });
      setDateToTimesMap(tempDateToTimesMap);
    }
  }, [musicalData]);

  interface ConfirmBoxProps {
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
  }
  const ConfirmBox: React.FC<ConfirmBoxProps> = ({ message, onConfirm, onCancel }) => {
    // ... rest of the component
    return (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="flex flex-col justify-center bg-white gap-8 p-8 rounded w-1/3 shadow-xl min-w-[350px] max-w-[500px] min-h-[250px] z-50">
          <div className="block w-full flex flex-col justify-center items-center">
            <img src={staticFiles.images.logo} width="100" alt="unifyq logo" />
       
          </div>
          <div className="w-full text-[#5D5D5F] text-center">{message}</div>
          <div className="flex justify-center">
            <button className="mr-2 bg-[#5D5D5F] text-white rounded px-4 py-2" onClick={onCancel}>
              Cancel
            </button>
            <button className="mr-2 bg-gray-300 px-4 py-2 rounded text-white bg-blue" onClick={onConfirm}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col max-w-[1300px] w-full mb-16 px-4">
      {showConfirm && <ConfirmBox message={`Do you want to select : ${selectedTime} for ${selectedDate}?`} onConfirm={handleConfirm} onCancel={handleCancel} />}
      <div className="flex w-full py-12 sm:flex gap-x-4" onClick={() => {}}>
        <div className="flex flex-col font-poppins gap-y-3">
          <span className="font-bold text-dark" style={{ fontSize: "24px" }}>
            {ticket?.title_en} [{ticket?.title_kr}]
          </span>
          <div className="flex flex-row">
            <img src={staticFiles.icons.schedule} width={25} />
            <span className="text-dark ml-5" style={{ fontSize: "16px" }}>
              {" "}
              Calendar / Seating Location & Ticket Price
            </span>
          </div>
        </div>
      </div>

      <div className="flex w-full gap-x-8 flex-col md:flex-row flex justify-center">
        <div className="flex flex-col w-full md:w-2/3 gap-y-8 ">
          <div className="container">
            <div className="calendar-container bg-white">
              <div>
                <Calendar
                  tileContent={({ date }) => {
                    const dateStr = date.toDateString();
                    if (isDateAvailable(date) && dateToTimesMap[dateStr]) {
                      return (
                        <div>
                          {dateToTimesMap[dateStr].map((time, idx) => (
                            <div
                              key={idx}
                              className={`time-slot flex flex-col pt-[5px] ${isDateClicked(date) && selectedTime === time ? "selected-time-slot-green" : "text-[#006edc]"}`}
                              onClick={() => {
                                setSelectedTime(time); // Immediately set the clicked time as selected
                                handleDateClick(date, time);
                              }}
                            >
                              {time}
                            </div>
                          ))}
                        </div>
                      );
                    }
                    return null;
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
