import { useNavigate } from "react-router-dom";
import { staticFiles } from "..";
import { MainButton } from "./Buttons";
import { SpaceY } from "./Utils";

export type ProductCardProps = {
  id: string;
  name: string;
  kr_name: string;
  image: string;
  isPremium: boolean;
  availability: string;
  adultPrice: number;
  adultSitePrice: number;
  childPrice: number;
  childSitePrice: number;
  childNote: string;
  additional_price_amount?: string;
  additional_price_image?: any;
  additional_price_type?: string;
  ticket_type?: string;
  ticket_sent_status?: string;
  premiumPrice: number;
  premiumSPrice: number;
  description: string;
  ageLimit?: any;
};

export const ProductCard: React.FC<ProductCardProps> = ({
  adultPrice,
  adultSitePrice,
  availability,
  childNote,
  childPrice,
  childSitePrice,
  name,
  kr_name,
  image,
  isPremium,
  id,
  additional_price_type,
  ticket_type,
  premiumPrice,
  premiumSPrice,
  ageLimit,
}) => {
  const navigate = useNavigate();
  let premiumStyle = "bg-orange-400";
  let title = "";
  let opacity = 0.9;
  if (additional_price_type === "None") {
    opacity = 0;
  } else if (additional_price_type === "Premium") {
    premiumStyle = "bg-orange-400";
    adultPrice = Number(adultPrice) + Number(premiumPrice);
    childPrice = Number(childPrice) + Number(premiumPrice);
    title = "Premium";
  } else if (additional_price_type === "Premium S") {
    premiumStyle = "bg-purple-400";
    adultPrice = Number(adultPrice) + Number(premiumSPrice);
    childPrice = Number(childPrice) + Number(premiumSPrice);
    title = "Premium S";
  }

  return (
    <>
      <div className="flex flex-col w-full px-4 py-4 bg-white sm:hidden gap-x-8">
        <div className="flex items-center max-h-[200px] relative">
          {/* <img className="object-cover h-full" src={image} /> */}
          <img alt="" className="sm:h-[667px] sm:w-[660px] h-[100%] w-[100%]" src={image} />
          <div className={`absolute p-3 text-sm text-white bg-orange-400 top-0 ${premiumStyle}`} style={{ opacity }}>
            {title}
          </div>
        </div>
        <SpaceY />
        <span className="text-dark">{name}</span>
        <SpaceY />
        <div className="flex flex-col items-start justify-center w-full p-2 gap-y-7">
          <div>
            <span className="mr-2 text-sm font-poppins text-gray">adult</span>
            <span className="font-poppins text-dark">${adultPrice}</span>
          </div>
          <div>
            <span className="mr-2 text-sm font-poppins text-gray">child</span>
            <span className="font-poppins text-dark">${childPrice}</span>
          </div>
        </div>
        <div className="flex flex-col w-full font-poppins gap-y-3">
          <SpaceY />
          <div className="flex gap-x-3">
            <img width={18} src={staticFiles.icons.card_calendar} />
            <span className="text-sm text-darkGray">{availability}</span>
          </div>
          <div className="flex gap-x-3">
            <img width={18} src={staticFiles.icons.card_adult} />
            <div className="flex flex-col">
              <span className="text-sm text-darkGray">성인 가격: ${adultSitePrice}</span>
              <span className="text-sm text-darkGray">현장 판매가: ${adultPrice}</span>
            </div>
          </div>
          <div className="flex gap-x-3">
            <img width={18} src={staticFiles.icons.card_baby} />
            <div className="flex flex-col">
              <span className="text-sm text-darkGray">어린이 가격: ${childSitePrice}</span>
              <span className="text-sm text-darkGray">현장 판매가: ${childPrice}</span>
              <span className="text-sm text-darkGray">{childNote}</span>
            </div>
          </div>
        </div>
        <SpaceY />
        <MainButton text="VIEW DETAILS" onClick={() => navigate(`/product-detail/${id}`)} containerClassName="w-full text-base h-12" />
      </div>

      {/*  */}
      <div className="flex hidden w-full py-8 bg-white sm:flex gap-x-4 justify-between rounded-b-3xl">
        <div className="flex flex-col items-center justify-center"></div>
        <div
          className="w-1/4 flex items-center justify-center
         max-w-[400px] relative mx-4"
        >
          {/* <img className="object-cover h-full" src={image} /> */}
          <div className="h-[240px] min-w-[215px]">
            <img alt="" className="h-full w-full object-cover " src={image} />
            <div className={`absolute p-3 text-sm text-white bg-orange-400 top-0 ${premiumStyle}`} style={{ opacity }}>
              {/* <div className="absolute" style={{ borderRightColor: premiumStyle}}> */}
              {title}
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-4/12 font-poppins gap-y-3 mx-4">
          <span className="text-xl text-[#5D5D5F]">{name}</span>
          <SpaceY />
          <div className="flex gap-x-3 text-[#5D5D5F]">
            <img className="fill-black" alt="" width={18} src={staticFiles.icons.volume_high} />
            <span className="text-sm text-darkGray">{availability}</span>
          </div>
          {
            ticket_type === "SIM card" &&
            <div className="flex gap-x-3 text-[#5D5D5F]">
              <img alt="" width={18} src={staticFiles.icons.card_adult} />
              <div className="flex flex-col">
                <span className="text-sm text-[#5D5D5F]">Sim 현장 가격: ${adultSitePrice}</span>
              </div>
            </div>
          }
          {ticket_type !== "SIM card" && Number(adultPrice) !== 0 ? (
            <div className="flex gap-x-3 text-[#5D5D5F]">
              <img alt="" width={18} src={staticFiles.icons.card_adult} />
              <div className="flex flex-col">
                <span className="text-sm text-[#5D5D5F]">어른 현장 판매가: ${adultSitePrice}</span>
              </div>
            </div>
          ) : (
            <></>
          )}

          {ticket_type !== "SIM card" && Number(childPrice) !== 0 ? (
            <div className="flex gap-x-3">
              <img alt="" width={18} src={staticFiles.icons.card_baby} />
              <div className="flex flex-col">
                <span className="text-sm text-[#5D5D5F]">어린이 현장 판매가: ${childSitePrice}</span>
                <span className="text-sm text-[#5D5D5F]">{childNote}</span>
              </div>
            </div>
          ) : (
            <></>
          )}
          {ageLimit ? (
            <div>
              <span className="text-sm text-[#5D5D5F] font-bold">{`만 ${ageLimit} 세 이하 무료`}</span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="flex flex-col items-center justify-center border-l border-gray gap-y-7"></div>
        <div className="flex flex-col items-center justify-center w-1/4 border-gray gap-y-7 px-4">
          {
            ticket_type === "SIM card" && <div>
              <span className="mr-2 text-sm font-poppins text-gray">Sim 가격</span>
              <span className="text-xl font-poppins text-[#5D5D5F]">${adultPrice}</span>
            </div>
          }
          {ticket_type !== "SIM card" && Number(adultPrice) !== 0 ? (
            <div>
              <span className="mr-2 text-sm font-poppins text-gray">adult</span>
              <span className="text-xl font-poppins text-[#5D5D5F]">${adultPrice}</span>
            </div>
          ) : (
            <></>
          )}
          {ticket_type !== "SIM card" && Number(childPrice) !== 0 ? (
            <div>
              <span className="mr-2 text-sm font-poppins text-gray">child</span>
              <span className="text-xl font-poppins text-[#5D5D5F]">${childPrice}</span>
            </div>
          ) : (
            <></>
          )}
          <MainButton text="VIEW DETAILS" onClick={() => navigate(`/product-detail/${id}`)} containerClassName="w-full text-base h-12" />
        </div>
        <div className="flex flex-col items-center justify-center"></div>
      </div>
    </>
  );
};
