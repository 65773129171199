/* eslint-disable jsx-a11y/anchor-is-valid */
import { Controller, useForm } from "react-hook-form";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { useNavigate, useLocation } from "react-router-dom";
import { GoogleButton, MainButton, NaverButton } from "../../shared/components/Buttons";
import { MainInput } from "../../shared/components/Inputs";
import { SpaceY } from "../../shared/components/Utils";
import { LoginValidator } from "./utils/validations";
import axios from "axios";
import React, { useState } from "react";
import { GoogleSignInButton } from "./components/Google";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const resolver = classValidatorResolver(LoginValidator);

const extractParams = (url: string) => {
  const urlParams = new URLSearchParams(new URL(url).search);
  const redirect = urlParams.get("redirect");
  return { redirect };
};

export const LogInView = () => {
  const navigate = useNavigate();
  const { search } = useLocation(); // ?email=jlfl94@gmail.com&order_number=4388608
  let { redirect } = extractParams("http://localhost:3000/my-bookings" + search);

  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver,
  });

  const onSubmit = (data: { email: string; password: string }) => {
    const { email, password } = data; // Destructure the email and password from the data object
    // toast.success("asf");
    axios
      .post("https://travelapi.unifyq.com/api/login", {
        email,
        password,
      })
      .then((res) => {
        console.log("response", res);
        localStorage.setItem("useremail", email);
        localStorage.setItem("authToken", res.data.token);
        localStorage.setItem("loginData", "email");
        localStorage.setItem("role", res.data.role.id);
        // toast.success("Login Successful!");

        if (redirect) {
          navigate(`/${redirect}`);
        } else {
          navigate("/my-bookings");
        }
      })
      .catch((error) => {
        setIsError(true);
        setErrorMessage(error.response.data.error);
        // toast.error(error.response.data.error);
        console.log("Error:", error);
        console.log("response erro", error.response.data);
      });
  };

  return (
    <div className="flex justify-center">
      <div className="min-h-[600px] flex flex-col items-center pt-[5vh] pb-[20vh]">
        <SpaceY />
        <span className="text-2xl font-bold font-volkhov text-dark">Login</span>
        <SpaceY /> <SpaceY />
        <Controller
          name="email"
          rules={{ required: true }}
          control={control}
          render={({ field }) => <MainInput value={field.value} error={errors.email?.message} placeholder="Email" onChange={(text) => field.onChange(text)} />}
        />
        <SpaceY />
        <Controller
          name="password"
          rules={{ required: true }}
          control={control}
          render={({ field }) => <MainInput value={field.value} error={errors.password?.message} isPassword placeholder="Password" onChange={(text) => field.onChange(text)} />}
        />
        {isError && <span className="w-[300px] text-sm pt-3 pl-2 font-poppins text-red ">{errorMessage}</span>}
        <SpaceY /> <SpaceY />
        <hr className="border border-gray rounded w-[300px]" />
        <SpaceY /> <SpaceY />
        <a onClick={() => navigate("/user/forgot-password")} className="cursor-pointer font-poppins text-blue text-sm w-[300px] text-end text-base">
          Forgot Password?
        </a>
        <SpaceY /> <SpaceY />
        <MainButton disabled={Boolean(Object.entries(errors).length)} text="Login" onClick={handleSubmit(onSubmit)} />
        <SpaceY />
        <SpaceY />
        {/* <GoogleButton text="Google Login" onClick={() => {}} /> */}
        {/* <GoogleSignInButton /> */}
        <SpaceY />
        <NaverButton text="Naver Login" onClick={() => {}} />
        <SpaceY />
        <SpaceY />
        <a onClick={() => navigate("/user/sign-up?redirect=cart")} className="font-base cursor-pointer font-poppins text-blue text-sm">
          Don't have an account?
        </a>
        <SpaceY />
        <SpaceY />
        <hr className="border border-gray rounded w-[300px]" />
        <SpaceY />
        <SpaceY />
        <a onClick={() => navigate("/user/non-member-order-lookup")} className="font-base cursor-pointer font-poppins text-blue text-sm">
          Order Look Up
        </a>
      </div>
    </div>
  );
};
