// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(249, 246, 246, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-overlay-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(249, 246, 246, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  /* height: 350px; */
  width: 350px;
  height: auto;
  position:absolute;
  outline: none;
  background: white;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 2px 2px 2px 2px rgb(226, 220, 220);
}

.modal-content-spinner {
  /* height: 350px; */
  width: 20%;
  position:absolute;
  outline: none;
  padding: 25px;
}

.modal-content-disable {
  width: 350px;
  position:absolute;
  outline: none;
  background: white;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 2px 2px 2px 2px rgb(226, 220, 220);
}

`, "",{"version":3,"sources":["webpack://./src/modules/bookings/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,0CAA0C;EAC1C,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,8CAA8C;AAChD;;AAEA;EACE,mBAAmB;EACnB,UAAU;EACV,iBAAiB;EACjB,aAAa;EACb,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,8CAA8C;AAChD","sourcesContent":[".modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  /* background: rgba(249, 246, 246, 0.5); */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.modal-overlay-spinner {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(249, 246, 246, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.modal-content {\n  /* height: 350px; */\n  width: 350px;\n  height: auto;\n  position:absolute;\n  outline: none;\n  background: white;\n  padding: 25px;\n  border-radius: 10px;\n  box-shadow: 2px 2px 2px 2px rgb(226, 220, 220);\n}\n\n.modal-content-spinner {\n  /* height: 350px; */\n  width: 20%;\n  position:absolute;\n  outline: none;\n  padding: 25px;\n}\n\n.modal-content-disable {\n  width: 350px;\n  position:absolute;\n  outline: none;\n  background: white;\n  padding: 25px;\n  border-radius: 10px;\n  box-shadow: 2px 2px 2px 2px rgb(226, 220, 220);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
