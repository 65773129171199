import { staticFiles } from "../../shared";

export const 여행정보View = () => {
  return (
    <div className="min-h-[600px] flex flex-col items-center pt-[5vh] pb-[20vh]">
      <div className="px-4 max-w-[1300px] w-full">
        <div className="py-[5vh] gap-x-5 md:block md:flex w-full justify-center pt-[5vh]">
          <img className="hidden xl:block" alt="" src={staticFiles.images.여행정보_image1} />
          <img className="w-full " alt="" src={staticFiles.images.여행정보_image2} />
        </div>
        <div className="py-[5vh] gap-x-5 w-full pt-[5vh] flex flex-col gap-8">
          <div className="flex w-full justify-center flex-col gap-2">
            <span className="flex grow justify-center font-volkhov font-bold text-dark text-2xl block"> NY Newsletter</span>
          </div>
          <div className="flex flex-col gap-8 items-center">
          <div className=" rounded-b-3xl flex flex-col md:flex-row gap-4 ">
              <div className=" bg-white rounded-b-3xl flex justify-center shadow-sm">
                <img className="block max-h-[335px] min-w-[460px] md:min-h-[335px] max-w-[460px] md:min-w-[460px] h-auto  p-6" alt="" src={staticFiles.images.여행정보_destination1} />
              </div>
              <div className="bg-white p-8 rounded-b-3xl shadow-sm">
                <h1 className="font-bold mb-4">뉴욕 여행의 시작 타임스퀘어에 위치</h1>
                <div>
                  <p>
                    한국 직원들을 통해 빅애플패스 안내 및 여행 정보도 상담 받을 수 있으니 망설임 없이
                    <br />
                    가벼운 마음으로 여행에만 집중하세요! <br />
                    궁금한 점은 카톡 문의도 가능하니 이렇게 든든한 여행 동반자 unifyq 뿐!
                  </p>
                </div>
              </div>
            </div>
            <div className=" rounded-b-3xl flex flex-col md:flex-row gap-4 ">
              <div className=" bg-white rounded-b-3xl flex justify-center shadow-sm">
                <img className="block max-h-[335px] min-w-[460px] md:min-h-[335px] max-w-[460px] md:min-w-[460px] h-auto  p-6" alt="" src={staticFiles.images.여행정보_destination2} />
              </div>
              <div className="bg-white p-8 rounded-b-3xl shadow-sm">
                <h1 className="font-bold mb-4">뉴욕 여행의 시작 타임스퀘어에 위치</h1>
                <div>
                  <p>
                    한국 직원들을 통해 빅애플패스 안내 및 여행 정보도 상담 받을 수 있으니 망설임 없이
                    <br />
                    가벼운 마음으로 여행에만 집중하세요! <br />
                    궁금한 점은 카톡 문의도 가능하니 이렇게 든든한 여행 동반자 unifyq 뿐!
                  </p>
                </div>
              </div>
            </div>
            <div className=" rounded-b-3xl flex flex-col md:flex-row gap-4 ">
              <div className=" bg-white rounded-b-3xl flex justify-center shadow-sm">
                <img className="block max-h-[335px] min-w-[460px] md:min-h-[335px] max-w-[460px] md:min-w-[460px] h-auto  p-6" alt="" src={staticFiles.images.여행정보_destination3} />
              </div>
              <div className="bg-white p-8 rounded-b-3xl shadow-sm">
                <h1 className="font-bold mb-4">뉴욕 여행의 시작 타임스퀘어에 위치</h1>
                <div>
                  <p>
                    한국 직원들을 통해 빅애플패스 안내 및 여행 정보도 상담 받을 수 있으니 망설임 없이
                    <br />
                    가벼운 마음으로 여행에만 집중하세요! <br />
                    궁금한 점은 카톡 문의도 가능하니 이렇게 든든한 여행 동반자 unifyq 뿐!
                  </p>
                </div>
              </div>
            </div>
            <div className=" rounded-b-3xl flex flex-col md:flex-row gap-4 ">
              <div className=" bg-white rounded-b-3xl flex justify-center shadow-sm">
                <img className="block max-h-[335px] min-w-[460px] md:min-h-[335px] max-w-[460px] md:min-w-[460px] h-auto  p-6" alt="" src={staticFiles.images.여행정보_destination4} />
              </div>
              <div className="bg-white p-8 rounded-b-3xl shadow-sm">
                <h1 className="font-bold mb-4">뉴욕 여행의 시작 타임스퀘어에 위치</h1>
                <div>
                  <p>
                    한국 직원들을 통해 빅애플패스 안내 및 여행 정보도 상담 받을 수 있으니 망설임 없이
                    <br />
                    가벼운 마음으로 여행에만 집중하세요! <br />
                    궁금한 점은 카톡 문의도 가능하니 이렇게 든든한 여행 동반자 unifyq 뿐!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
