import { staticFiles } from "..";

export const ShowCover = () => (
  <div className="min-h-min">
    <img className="" src={staticFiles.images.cover_show} />
    {/* <div className="flex justify-center relative">
      <div className="absolute top-[-180px] lg:top-[-18vw] h-[180px] w-full z-10 flex items-center px-[5vw] max-w-[1300px] border">
        asdsad 
      </div>
    </div> */}
  </div>
);
