import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { strict } from "assert";

import { MainButton } from "../../shared/components/Buttons";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import PasswordStrengthBar from "react-password-strength-bar";
import { CheckBox, MainInput, PhoneInput } from "../../shared/components/Inputs";
import { SpaceY } from "../../shared/components/Utils";
import { RegisterValidator } from "./utils/validations";

const resolver = classValidatorResolver(RegisterValidator);

const extractParams = (url: string) => {
  const urlParams = new URLSearchParams(new URL(url).search);
  const redirect = urlParams.get("redirect");
  return { redirect };
};

export const RegisterView = () => {
  const navigate = useNavigate();
  const { search } = useLocation(); // ?email=jlfl94@gmail.com&order_number=4388608
  let { redirect } = extractParams("http://localhost:3000/my-bookings" + search);

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    clearErrors,
  } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
      email_confirmation: "",
      password: "",
      password_confirmation: "",
      phone: "",
    },
    resolver,
  });
  const [phoneNumber, setPhoneNumber] = useState<string>();

  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const formData = new FormData();

  const onSubmit = (data: { firstname: string; lastname: string; email: string; email_confirmation: string; password: string; password_confirmation: string; phone: string | undefined }) => {
    data.phone = phoneNumber;
    handleRegister(data);

    console.log(data, phoneNumber);
  };
  const setPasswordConformError = (text: any) => {
    if (password !== text)
      return setError("password_confirmation", {
        message: "Password does not match",
      });
    else return clearErrors("password_confirmation");
  };

  const setEmailConfirmError = (text: string) => {
    if (email === text) {
      return clearErrors("email_confirmation");
    } else {
      return setError("email_confirmation", {
        message: "Email does not match!",
      });
    }
  };
  const setPasswordError = (password: any) => {
    let caps, small, num, specialSymbol;
    if (password.length < 4) {
      return setError("password", {
        message: "Password should contain minimum 4 characters, with one UPPERCASE, lowercase, number and special character: @$! % * ? &",
      });
    } else {
      caps = (password.match(/[A-Z]/g) || []).length;
      small = (password.match(/[a-z]/g) || []).length;
      num = (password.match(/[0-9]/g) || []).length;
      specialSymbol = (password.match(/\W/g) || []).length;
      if (caps < 1) {
        return setError("password", {
          message: "Must add one UPPERCASE letter",
        });
      } else if (small < 1) {
        return setError("password", {
          message: "Must add one lowercase letter",
        });
      } else if (num < 1) {
        return setError("password", {
          message: "Must add one number",
        });
      } else if (specialSymbol < 1) {
        return setError("password", {
          message: "Must add one special symbol: @$! % * ? &",
        });
      }
      return clearErrors("password");
    }
  };
  const handleRegister = async (data: any) => {
    data.role = 5;
    await axios
      .post("https://travelapi.unifyq.com/api/register", data)
      .then((res) => {
        if (redirect) {
          navigate(`/user/log-in?redirect=${redirect}`);
        } else {
          navigate("/user/log-in");
        }
        console.log("response=========", res.data);
        // navigate("/user/login")
      })
      .catch((error) => {
        const err = error.response.data;
        return setError("email_confirmation", {
          message: err.errors.email[0],
        });
      });
  };

  return (
    <div className="flex justify-center">
      <div className="min-h-[600px] w-[300px] flex flex-col  items-center pt-[5vh] pb-[20vh]">
        <SpaceY />
        <span className="text-2xl font-bold font-volkhov text-dark">Register</span>
        <SpaceY /> <SpaceY />
        <span className="text-sm font-poppins">After creating an account, you'll be able to track your schedule and edit you purchased.</span>
        <SpaceY /> <SpaceY /> <SpaceY />
        <Controller
          name="firstname"
          rules={{ required: true }}
          control={control}
          render={({ field }) => <MainInput value={field.value} error={errors.firstname?.message} placeholder="First Name *" onChange={(text) => field.onChange(text)} />}
        />
        <SpaceY />
        <Controller
          name="lastname"
          rules={{ required: true }}
          control={control}
          render={({ field }) => <MainInput value={field.value} error={errors.lastname?.message} placeholder="Last Name *" onChange={(text) => field.onChange(text)} />}
        />
        <SpaceY />
        <Controller
          name="email"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <MainInput
              value={field.value}
              error={errors.email?.message}
              placeholder="Email *"
              onChange={(text) => {
                field.onChange(text);
                setEmail(text);
              }}
            />
          )}
        />
        <SpaceY />
        <Controller
          name="email_confirmation"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <MainInput
              value={field.value}
              error={errors.email_confirmation?.message}
              placeholder="Retype Email *"
              onChange={(text) => {
                setEmailConfirmError(text);
                field.onChange(text);
              }}
            />
          )}
        />
        <SpaceY />
        <Controller
          name="password"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <MainInput
              value={field.value}
              error={errors.password?.message}
              isPassword
              placeholder="Password *"
              onChange={(text) => {
                setPasswordError(text);
                field.onChange(text);
                setPassword(text);
              }}
            />
          )}
        />
        <PasswordStrengthBar style={{ width: 300 }} password={password} />
        <SpaceY />
        <Controller
          name="password_confirmation"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <MainInput
              value={field.value}
              error={errors.password_confirmation?.message}
              isPassword
              placeholder="Retype password *"
              onChange={(text) => {
                setPasswordConformError(text);
                field.onChange(text);
              }}
            />
          )}
        />
        <SpaceY />
        <PhoneInput
          number={phoneNumber}
          error={errors.phone?.message}

          setError={() =>
            setError("phone", {
              message: "Invalid phone number",
            })
          }
          clearError={() => clearErrors("phone")}
          onChange={(text) => {
            setPhoneNumber(text);
          }}
        />
        <SpaceY />
        <SpaceY />
        <div className="flex justify-between items-center w-[300px]">
          <CheckBox value={termsAndConditions} onCheck={() => setTermsAndConditions((prev) => !prev)} containerClass="w-2/12" />
          <div className="w-10/12 text-xs font-poppins">* Creating an account means you're okay with our Terms of Service and Privacy Statement.</div>
        </div>
        <SpaceY />
        <SpaceY />
        <MainButton disabled={Boolean(Object.entries(errors).length) || !termsAndConditions} text="Sign up" onClick={handleSubmit(onSubmit)} />
      </div>
    </div>
  );
};
