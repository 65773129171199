import React, { useEffect, useState, ChangeEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "react-modal";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import { staticFiles } from "../../../shared";
import { MainButton, SecondaryButton } from "../../../shared/components/Buttons";
import { SelectInput } from "../../../shared/components/Inputs";
import { SpaceY, formatDateTime, formatDateTimePMTo00 } from "../../../shared/components/Utils";
import { ProductCardProps } from "../../../shared/components/ProductCard";
import { CartItem, cartState, menuState } from "../../../App";
import { DatePicker } from "react-date-picker";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { isSameDay } from "date-fns";
import { Calendar } from "@progress/kendo-react-dateinputs";
import "@progress/kendo-theme-default/dist/all.css";
import { MedalEnum } from "../../cart/components/CardInfo";
import { useGetPackagePriceOptions } from "../../../shared/hooks";
import { reservationsState, reservationsParsedState, cityIdState } from "../../../App";
import { TourCalendar } from "../../../shared/components/Calendar/TourCalendar";
import { MultiTourCalendar } from "../../../shared/components/Multi-calendar/MultiTourCalendar";

type IncludesInfoProps = {
  isIncluded: boolean;
  isSelected: boolean;
  canEdit: boolean;
  hasGoldStar?: boolean;
  hasDate?: boolean;
  name: string;
  premiumType: string;
  onClick: () => void;
  setIsOpenModal: any;
  index: number;
  setOrder: any;
};

type cartData = {
  adultInfo: CartItem[];
  childInfo: CartItem[];
};

const IncludesInfo: React.FC<IncludesInfoProps> = ({ hasDate, canEdit, isIncluded, isSelected, name, premiumType, onClick, setIsOpenModal, index, setOrder }) => (
  <div
    onClick={
      canEdit
        ? (e) => {
          onClick();
        }
        : undefined
    }
    className="w-full flex items-center cursor-pointer hover:bg-[rgb(240,240,240)] py-2"
  >
    <div className="flex items-center w-1/12">
      <img alt="" width={20} src={isIncluded ? staticFiles.icons.green_check : staticFiles.icons.black_check} />
    </div>
    <div className="flex items-center  w-2/12">
      {hasDate}
      {premiumType === "Premium" ? (
        <img alt="" style={{ width: "20px", marginRight: "5px" }} src={staticFiles.icons.black_medal} />
      ) : premiumType === "Premium S" ? (
        <img alt="" style={{ width: "20px", marginRight: "5px" }} src={staticFiles.icons.gold_medal} />
      ) : (
        <div style={{ width: "20px", marginRight: "5px" }}></div>
      )}

      {hasDate && (
        <img
          alt=""
          onClick={
            canEdit
              ? (e) => {
                e.stopPropagation();
                setOrder(index);
                setIsOpenModal(true);
              }
              : undefined
          }
          style={{ width: "20px" }}
          src={isIncluded && isSelected ? staticFiles.icons.greenCalendar : staticFiles.icons.calendar}
        />
      )}
    </div>
    <div className="flex w-9/12">
      <span className="text-sm text-[#5D5D5F]">{name}</span>
    </div>
  </div>
);

const extractParams = (url: string) => {
  const urlParams = new URLSearchParams(new URL(url).search);
  const edit = urlParams.get("edit");
  const cartId = urlParams.get("cartId");
  const type = urlParams.get("type");
  return { edit, cartId, type };
};

const calculateAdditionalPrice = (selectedTicketIds: any, tickets: any) => {
  return selectedTicketIds.reduce((acc: any, ticketId: any) => {
    const ticket = tickets.find((item: any) => item.id === ticketId);
    const premiumPrice = ticket?.additional_price_type === "Premium" ? Number(ticket?.premiumPrice) : 0;
    const premiumSPrice = ticket?.additional_price_type === "Premium S" ? Number(ticket?.premiumSPrice) : 0;
    return acc + premiumPrice + premiumSPrice;
  }, 0);
};

const calculateTotalPrice = (selectedAdultChildType: string, selectedPriceOptionId: any, additionalPrice: number, filterCounter: number, packagePriceOptions: any) => {
  const priceOption = packagePriceOptions.find((item: any) => item.value === selectedPriceOptionId);
  const adultPrice = selectedAdultChildType === "Adult" ? (Number(priceOption?.adult_price || 0) + additionalPrice) * filterCounter : 0;
  const childPrice = selectedAdultChildType === "Child" ? (Number(priceOption?.child_price || 0) + additionalPrice) * filterCounter : 0;
  return adultPrice + childPrice;
};

const checkDoubleBookingEdit = (
  {
    childInfo,
    adultInfo,
  }: {
    childInfo: CartItem[];
    adultInfo: CartItem[];
  },
  reservation_id: any
) => {
  let result = false;

  if (reservation_id) {
    const combinedInfo = [...childInfo, ...adultInfo];

    result = combinedInfo.some((info) => info.reservation_id === reservation_id);
  }

  return result;
};
const checkIsEditItems = ({ childInfo, adultInfo }: { childInfo: CartItem[]; adultInfo: CartItem[] }) => {
  let result = false;

  const combinedInfo = [...childInfo, ...adultInfo];

  result = combinedInfo.some((info) => info.reservation_id !== null);

  return result;
};

export const PackageBuyDetail = ({
  handlePage,
  tickets,
  singleTicket,
  allTickets,
  categoryId,
  subCategoryId,
  subCategoryTitle,
  subPath,
  tour,
  ticket,
}: {
  handlePage?: any;
  allTickets?: any;
  tickets: ProductCardProps[];
  singleTicket?: any;
  categoryId?: any;
  subCategoryId?: any;
  subCategoryTitle?: string;
  subPath?: string;
  tour?: boolean;
  ticket?: any;
}) => {
  const [cart, setCart] = cartState.useState();
  const { search } = useLocation(); // ?edit=true&cartId=1690069215841
  const location = useLocation();
  const navigate = useNavigate();
  const item = location.state;

  const [filterCounter, setFilterCounter] = useState<number>(1); // user selected ticket count
  const [selectedPriceOptionId, setSelectedPriceOptionId] = useState<string>(""); // price option id e.g. 88, 89, 90
  const [selectedAdultChildType, setSelectedAdultChildType] = useState<"Adult" | "Child">("Adult"); // adult or child
  const [maxLimit, setMaxLimit] = useState(0);
  const [selectedPriceOption, setSelectedPriceOption] = useState({} as any);
  const [selectedOriginPriceOption, setSelectedOriginPriceOption] = useState({} as any);
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedTicketIds, setSelectedTicketIds] = useState<string[]>([]); // id of selected tickets
  const [noneditableTicketIds, setNoneditableTicketIds] = useState<string[]>([]);
  const [selectedOriginTicketIds, setSelectedOriginTicketIds] = useState<string[]>([]); // id of selected original tickets
  const [cartBookingCount, setCartBookingCount] = useState(0);
  const [editLoading, setEditLoading] = useState(false);
  const [reservationsData] = reservationsState.useState();
  const [reservationsParsedData] = reservationsParsedState.useState();
  const [cityId] = cityIdState.useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTour, setSelectedTour] = useState<any>([]);
  const [order, setOrder] = useState(0);
  const { isLoading, isError, data: packagePriceOptions } = useGetPackagePriceOptions(subCategoryId, categoryId);

  const [menu] = menuState.useState();

  const handleReset = () => {
    setFilterCounter(1);
    setSelectedTicketIds([]);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // handleCart({ bookingDate });
  };

  const handleClick = (date: string, id: string): Promise<void> => {
    return new Promise((resolve) => {
      setSelectedTour((prevState: any) => {
        if (prevState.length !== 0) {
          let flg = false;
          const newArray = prevState.map((obj: any) => {
            if (Object.keys(obj)[0] === id) {
              flg = true;
              return { [id]: date };
            } else {
              return obj;
            }
          });
          if (flg) return newArray;
          else return [...newArray, { [id]: date }];
        } else {
          return [{ [id]: date }];
        }
      });
      resolve();
    });
  };

  const searchQuery = extractParams("http://localhost:3000" + search);

  useEffect(() => {
    const additionalPrice = calculateAdditionalPrice(selectedTicketIds, tickets);
    if (subCategoryId !== menu[1]?.dropdownElements?.[1].id) {
      if (packagePriceOptions && selectedPriceOptionId) {
        const calculatedTotalPrice = calculateTotalPrice(selectedAdultChildType, selectedPriceOptionId, additionalPrice, filterCounter, packagePriceOptions);
        setTotalPrice(calculatedTotalPrice);
      }
    } else {
      if (packagePriceOptions) {
        if (selectedAdultChildType === "Adult") {
          setTotalPrice(packagePriceOptions[0].adult_price * filterCounter);
        } else {
          setTotalPrice(packagePriceOptions[0].child_price * filterCounter);
        }
      }
    }
  }, [selectedPriceOptionId, selectedAdultChildType, filterCounter, selectedTicketIds, tickets, packagePriceOptions]);

  async function handleCartItems(cartItemsToEdit: any, type: string) {
    for (const it of cartItemsToEdit) {
      if (type === "cart") {
        if (it.tour_date && it.ticket_id) {
          await handleClick(formatDateTime(it.tour_date), it.ticket_id);
        }
      } else if (type === "cartbooking") {
        if (it.tour_date && it.ticket_id) {
          await handleClick(formatDateTime(it.tour_date), it.ticket_id?.split("+")[0]);
        }
      } else {
        if (it.rq_schedule_datetime && it.ticket_id) {
          await handleClick(formatDateTime(it.rq_schedule_datetime), it.ticket_id);
        }
      }
    }
  }

  useEffect(() => {
    setSelectedTour([]);
    setSelectedTicketIds([]);
  }, [cityId]);
  useEffect(() => {
    if (!isLoading && !isError && packagePriceOptions) {
      const searchQuery = extractParams("http://localhost:3000" + search);

      if (searchQuery?.edit && (searchQuery?.type === "cart" || searchQuery?.type === "cart booking")) {
        let cartData = localStorage.getItem("CART_DATA") ? JSON.parse(localStorage.getItem("CART_DATA") || "") : { adultInfo: [], childInfo: [] };

        if (cart.adultInfo.length > 0 || cart.childInfo.length > 0) {
          const { adultInfo, childInfo }: cartData = cart;
          const cartItemsToEdit = [...adultInfo, ...childInfo]?.filter((obj) => obj?.cartId === searchQuery?.cartId);

          const priceOption = packagePriceOptions.filter((option: any) => option.value === cartItemsToEdit[0]?.priceOptionId)[0];

          setFilterCounter(cartItemsToEdit[0]?.quantity);
          setSelectedPriceOptionId(cartItemsToEdit[0]?.priceOptionId || "");
          setSelectedAdultChildType(cartItemsToEdit[0]?.adult_child_type || "Adult");
          setMaxLimit(priceOption?.quantity);
          setSelectedPriceOption(priceOption);
          if (cartItemsToEdit[0]?.subCategoryName !== "city-pass" && cartItemsToEdit[0]?.subCategoryName !== "explore-pass") {
            if (searchQuery?.type === "cart booking") {
              setSelectedTicketIds(cartItemsToEdit.map((item) => item.ticket_id?.split("+")[0] || ""));
              // Set more pages to show based on item.tickets
              let ticketsToDisplay = cartItemsToEdit.map((com: any) => Number(com?.ticket_id?.split("+")[0]));
              let maxTicketId = Math.max(...ticketsToDisplay);
              handlePage(allTickets?.findIndex((it: any) => it.id === maxTicketId.toString()) < 5 ? 5 : allTickets?.findIndex((it: any) => it.id === maxTicketId.toString()) + 1);

              handleCartItems(cartItemsToEdit, "cartbooking");
            } else {
              setSelectedTicketIds(cartItemsToEdit.map((item) => item.ticket_id || ""));
              // Set more pages to show based on item.tickets
              let ticketsToDisplay = cartItemsToEdit.map((com: any) => Number(com?.ticket_id));
              let maxTicketId = Math.max(...ticketsToDisplay);
              handlePage(allTickets?.findIndex((it: any) => it.id === maxTicketId.toString()) < 5 ? 5 : allTickets?.findIndex((it: any) => it.id === maxTicketId.toString()) + 1);

              handleCartItems(cartItemsToEdit, "cart");
            }
          }
          setSelectedOriginTicketIds(item?.tickets.map((com: any) => com?.ticket_id || ""));
        }
      }

      if (searchQuery?.edit && searchQuery?.type === "booking") {
        const priceOption = packagePriceOptions.filter((option: any) => option.value === item?.price_list_id)[0];

        setFilterCounter(Number(item?.quantity));
        setSelectedPriceOptionId(item?.price_list_id || "");
        setSelectedAdultChildType(item?.adult_child_type || "Adult");
        setMaxLimit(priceOption?.quantity);
        setSelectedPriceOption(priceOption);
        setSelectedOriginPriceOption(priceOption);
        setSelectedTicketIds(item?.tickets.map((ticket: any) => ticket?.ticket_id || ""));

        setNoneditableTicketIds(item?.tickets.map((ticket: any) => (ticket.options_schedules.length !== 0 ? ticket?.ticket_id : "")));
        // Set more pages to show based on item.tickets
        let ticketsToDisplay = item?.tickets.map((com: any) => Number(com?.ticket_id));
        let maxTicketId = Math.max(...ticketsToDisplay);

        handlePage(allTickets?.findIndex((it: any) => it.id === maxTicketId.toString()) < 5 ? 5 : allTickets?.findIndex((it: any) => it.id === maxTicketId.toString()) + 1);

        setSelectedOriginTicketIds(item?.tickets.map((com: any) => com?.ticket_id || ""));

        handleCartItems(item?.tickets, "booking");
      }

      setEditLoading(true);
    }
  }, [isLoading, isError, packagePriceOptions, allTickets]);

  useEffect(() => {
    if (!isLoading && !isError && packagePriceOptions) {
      const searchQuery = extractParams("http://localhost:3000" + search);

      if (!searchQuery?.edit) {
        setSelectedPriceOptionId(packagePriceOptions[0].value);
        setMaxLimit(packagePriceOptions[0].quantity);
        setSelectedPriceOption(packagePriceOptions[0]);
      }
    }
  }, [cityId, isLoading, isError, packagePriceOptions]);

  useEffect(() => {
    return () => {
      setFilterCounter(1);
      setSelectedPriceOptionId("");
      setSelectedAdultChildType("Adult");
      setMaxLimit(0);
      setSelectedPriceOption({});
      setTotalPrice(0);
      setSelectedTicketIds([]);
      Swal.close();
    };
  }, []);

  const handleQuantityCounter = (prev: number, action: "INC" | "DEC") => {
    if (action === "DEC" && prev === 0) {
      toast.warning("Quantity must be 1 or higher");
      return;
    }
    if (action === "DEC") setFilterCounter(prev - 1);
    if (action === "INC") setFilterCounter(prev + 1);
  };

  const handleUpdateCart = (adultOrder: any, childOrder: any) => {
    let isEdited = checkDoubleBookingEdit(cart, item?.reservation_id);
    if (searchQuery?.type == "cart booking" && cartBookingCount == 0) {
      isEdited = false;
      setCartBookingCount(1);
    }
    if (item && totalPrice < item?.total) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Downgrading is not allowed!",
      });
    } else if (item && totalPrice == item?.total && searchQuery?.type != "cart booking") {
      Swal.fire({
        title: "Do you want to update?",
        text: "You have no balance to pay and the change will be updated in My Bookings.",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: "info",
            title: "Updating now...",
            text: "Please Wait!",
            showConfirmButton: false,
            confirmButtonText: "",
          });

          const token = localStorage.getItem("authToken");
          const orderLoginData = localStorage.getItem("orderLoginNumber");
          const orderLoginEmail = localStorage.getItem("orderLoginEmail");

          if (token || orderLoginData) {
            let items: any = [];
            let reservations = reservationsData.reservations;

            let currentReservation: any = reservations[reservations.findIndex((it: any) => it.id === item.reservation_id)];

            let currentItemBeforeUpdate: any = currentReservation?.reservation_items.filter((it: any) => it.id == item.item_id);

            let otherItems = currentReservation.reservation_items.filter((it: any) => it.id !== item.item_id);
            let otherItemsTosend = otherItems.map((data: any) => ({
              id: data.id,
              adult_child_type: data.adult_child_type,
              child_age: data.child_age,
              price: parseFloat(data.price) || 0,
              quantity: parseInt(data.quantity),
              ticket_sent_status: data.ticket_sent_status,
              ticket_sent_date: data.ticket_sent_date,
              refund_status: data.refund_status,
              refund_sent_date: data.refund_sent_date,
              reservation_id: parseFloat(data.reservation_id),
              category_id: parseFloat(data.category_id),
              subcategory_id: parseFloat(data.subcategory_id),
              price_list_id: parseFloat(data.price_list_id),
              sub_items:
                data.reservation_sub_items?.map((subitem: any) => ({
                  id: subitem.id,
                  rq_schedule_datetime: subitem.rq_schedule_datetime,
                  ticket_id: subitem.ticket_id,
                  refund_status: subitem.refund_status,
                })) || [],
            }));

            items.push(...otherItemsTosend);

            let currentItem: any = {
              id: item.item_id,
              adult_child_type: selectedAdultChildType,
              child_age: item.child_age,

              price: item.price,
              quantity: filterCounter,

              ticket_sent_status: null,
              ticket_sent_date: null,
              refund_status: null,
              refund_sent_date: null,

              reservation_id: item.reservation_id,

              category_id: categoryId,
              subcategory_id: subCategoryId,
              price_list_id: selectedPriceOption?.value,

              sub_items: [
                ...selectedTicketIds.map((it: any, index: number) => {
                  const newTicket = tickets.find((ticket: any) => ticket.id === it);
                  let newDate = null;
                  if (newTicket?.ticket_type === "Guide Tour") {
                    const tour = selectedTour.find((obj: any) => obj.hasOwnProperty(newTicket.id));
                    newDate = formatDateTimePMTo00(tour[parseInt(newTicket.id)]);
                  }
                  return {
                    id: index < item.tickets.length ? item.tickets[index].subitem_id : null,
                    rq_schedule_datetime:
                      item.tickets.findIndex((ticket: any) => ticket.ticket_id === it) === -1
                        ? newDate
                        : item.tickets[item.tickets.findIndex((ticket: any) => ticket.ticket_id === it)]?.rq_schedule_datetime,
                    ticket_id: String(it),
                    refund_status: false,
                  };
                }),
              ],
            };

            items.push(currentItem);

            try {
              const result = await axios.put(`https://travelapi.unifyq.com/tickets-api-new/public/api/reservations/user-create/${item.reservation_id}`, {
                items,
                stripe_token: null,
              });

              if (result.status === 200) {
                await Swal.fire({
                  icon: "success",
                  title: "Success!",
                  text: "My Bookings will have the update. Please check.",
                  showConfirmButton: true,
                  confirmButtonText: "Go to My Bookings",
                });

                if (token) {
                  navigate("/my-bookings");
                } else {
                  navigate(`/my-bookings?email=${orderLoginEmail}&order_number=${orderLoginData}`);
                }
              } else {
                Swal.fire({
                  icon: "warning",
                  title: "Something wrong!",
                  text: "Please try again!",
                  confirmButtonText: "OK",
                });
              }
            } catch (error) {
              Swal.fire({
                icon: "warning",
                title: "Something wrong!",
                text: "Please try again!",
                confirmButtonText: "OK",
              });
            }
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          return;
        }
      });
    } else if (isEdited) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You already Edited, please check Shopping Cart!",
      });
    } else {
      let rawData: any = localStorage.getItem("CART_DATA");
      if (!rawData) {
        rawData = JSON.stringify({
          adultInfo: [],
          childInfo: [],
        });
      }
      if (rawData) {
        const { adultInfo, childInfo }: cartData = JSON.parse(rawData);

        const searchQuery = extractParams("http://localhost:3000" + search);

        let adultInfoToConcat = adultInfo;
        let childInfoToConcat = childInfo;

        if (searchQuery?.edit && (searchQuery?.type == "cart" || searchQuery?.type == "cart booking")) {
          adultInfoToConcat = adultInfoToConcat.filter((ticket) => ticket.cartId !== searchQuery?.cartId);
          childInfoToConcat = childInfoToConcat.filter((ticket) => ticket.cartId !== searchQuery?.cartId);
        }

        localStorage.setItem(
          "CART_DATA",
          JSON.stringify({
            adultInfo: adultInfoToConcat.concat(adultOrder),
            childInfo: childInfoToConcat.concat(childOrder),
          })
        );

        setCart({
          adultInfo: adultInfoToConcat.concat(adultOrder),
          childInfo: childInfoToConcat.concat(childOrder),
        });

        if (searchQuery?.edit && (searchQuery?.type == "cart" || searchQuery?.type == "cart booking")) {
          toast.info("You items has been successfully edited!");
        } else {
          toast.success("Success! Items have been added to the cart!");
        }
      }
    }
  };

  const handleAddToTheCart = () => {
    if (filterCounter < 1) {
      toast.warning("Cannot add items. Please choose more than 1 quantity");
      return;
    } else if (selectedTicketIds.length < maxLimit && subCategoryId === menu[1]?.dropdownElements?.[0].id) {
      toast.warning(`Please choose ${maxLimit - selectedTicketIds.length} more tickets`);
      return;
    } else if (selectedTicketIds.length > maxLimit) {
      toast.warning(`Cannot choose more than ${maxLimit} tickets`);
      return;
    }

    let isEditItems = checkIsEditItems(cart);
    if (isEditItems && searchQuery?.type != "cart booking" && searchQuery?.type != "booking") {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "You have Booking Edit Items, please check Shopping Cart first!",
      });
    } else {
      let cartId = String(Date.now());
      const searchQuery = extractParams("http://localhost:3000" + search);
      if (searchQuery?.edit && (searchQuery?.type == "cart" || searchQuery?.type != "cart booking")) {
        cartId = searchQuery?.cartId || String(Date.now());
      }
      const childInfo: CartItem[] = [];
      const adultInfo: CartItem[] = [];

      // save the original data in shopping cart to compare
      let flg = false;
      if (searchQuery?.edit && searchQuery?.type === "booking") {
        for (const el of selectedOriginTicketIds) {
          const ticketData = tickets?.find((item) => item.id === el);
          let addition = 0;

          if (ticketData?.additional_price_type === "Premium") {
            addition = Number(ticketData?.premiumPrice || 0);
          } else if (ticketData?.additional_price_type === "Premium S") {
            addition = Number(ticketData?.premiumSPrice || 0);
          }

          let date = selectedTour.find((obj: any) => obj.hasOwnProperty(el));
          if (!!date) {
            date = date[parseInt(el)];
          }

          if (selectedAdultChildType === "Adult") {
            adultInfo.push({
              cartId: String(Number(cartId) - 1),
              tour_date: date,
              reservation_id: item.reservation_id,
              item_id: item.item_id,
              categoryId: String(categoryId),
              name: ticketData?.name || "",
              price: Number(ticketData?.adultPrice) || 0,
              quantity: Number(item?.quantity),
              subtotal: -100,
              priceOptionTitle: selectedOriginPriceOption?.text,
              priceOptionId: String(selectedOriginPriceOption?.value),
              addition,
              additional_price_type: ticketData?.additional_price_type,
              additional_price_image: ticketData?.additional_price_image,
              subCategoryId: subCategoryId,
              subCategoryTitle: subCategoryTitle,
              subCategoryPrice: Number(selectedOriginPriceOption?.adult_price || 0),
              ticket_type: ticketData?.ticket_type,
              ticket_id: String(ticketData?.id),
              subPath,
              adult_child_type: "Adult",
            });
          } else if (selectedAdultChildType === "Child") {
            childInfo.push({
              cartId: String(Number(cartId) - 1),
              tour_date: date,
              reservation_id: item.reservation_id,
              item_id: item.item_id,
              categoryId: String(categoryId),
              name: ticketData?.name || "",
              price: Number(ticketData?.childPrice) || 0,
              quantity: Number(item?.quantity),
              subtotal: -100,
              priceOptionTitle: selectedOriginPriceOption?.text,
              priceOptionId: String(selectedOriginPriceOption?.value),
              addition,
              additional_price_type: ticketData?.additional_price_type,
              additional_price_image: ticketData?.additional_price_image,
              subCategoryId: subCategoryId,
              subCategoryTitle: subCategoryTitle,
              subCategoryPrice: Number(selectedOriginPriceOption?.child_price || 0),
              ticket_type: ticketData?.ticket_type,
              ticket_id: String(ticketData?.id),
              subPath,
              adult_child_type: "Child",
            });
          }
        }
      }
      let leftSubItems = item?.tickets.filter((it: any) => !selectedTicketIds.includes(it.ticket_id));

      for (const el of selectedTicketIds) {
        let subitem_id =
          selectedOriginTicketIds?.findIndex((it: any) => it == el) > -1
            ? item?.tickets[selectedOriginTicketIds.findIndex((it: any) => it == el)].subitem_id
            : leftSubItems?.length > 0
              ? leftSubItems?.pop()?.subitem_id
              : null;

        // Convert date format so that cart view handle properly
        const ticketData = tickets?.find((item) => item.id === el);
        if (!selectedTour.some((obj: any) => obj.hasOwnProperty(el)) && ticketData?.ticket_type === "Guide Tour") {
          flg = true;
          break;
        }
        let date = selectedTour.find((obj: any) => obj.hasOwnProperty(el));
        if (!!date) {
          date = date[parseInt(el)];
        }
        let addition = 0;
        if (ticketData?.additional_price_type === "Premium") {
          addition = Number(ticketData?.premiumPrice || 0);
        } else if (ticketData?.additional_price_type === "Premium S") {
          addition = Number(ticketData?.premiumSPrice || 0);
        }

        if (searchQuery?.edit && searchQuery?.type === "booking") {
          if (selectedAdultChildType === "Adult") {
            adultInfo.push({
              cartId,
              tour_date: date,
              reservation_id: item.reservation_id,
              item_id: item.item_id,
              categoryId: String(categoryId),
              name: ticketData?.name || "",
              price: Number(ticketData?.adultPrice) || 0,
              quantity: Number(filterCounter),
              subtotal: totalPrice,
              priceOptionTitle: selectedPriceOption?.text,
              priceOptionId: String(selectedPriceOption?.value),
              addition,
              additional_price_type: ticketData?.additional_price_type,
              additional_price_image: ticketData?.additional_price_image,
              subCategoryId: subCategoryId,
              subCategoryTitle: subCategoryTitle,
              subCategoryPrice: Number(selectedPriceOption?.adult_price || 0),
              ticket_type: ticketData?.ticket_type,
              ticket_id: String(el) + "+" + String(subitem_id),
              subPath,
              adult_child_type: "Adult",
            });
          } else if (selectedAdultChildType === "Child") {
            childInfo.push({
              cartId,
              tour_date: date,
              reservation_id: item.reservation_id,
              item_id: item.item_id,
              categoryId: String(categoryId),
              name: ticketData?.name || "",
              price: Number(ticketData?.childPrice) || 0,
              quantity: Number(filterCounter),
              subtotal: totalPrice,
              priceOptionTitle: selectedPriceOption?.text,
              priceOptionId: String(selectedPriceOption?.value),
              addition,
              additional_price_type: ticketData?.additional_price_type,
              additional_price_image: ticketData?.additional_price_image,
              subCategoryId: subCategoryId,
              subCategoryTitle: subCategoryTitle,
              subCategoryPrice: Number(selectedPriceOption?.child_price || 0),
              ticket_type: ticketData?.ticket_type,
              ticket_id: String(el) + "+" + String(subitem_id),
              subPath,
              adult_child_type: "Child",
            });
          }
        } else {
          if (selectedAdultChildType === "Adult") {
            adultInfo.push({
              cartId,
              tour_date: date,
              reservation_id: searchQuery?.type == "cart booking" ? item.reservation_id : null,
              item_id: searchQuery?.type == "cart booking" ? item.item_id : null,
              categoryId: String(categoryId),
              name: ticketData?.name || "",
              price: ticketData?.adultPrice || 0,
              quantity: filterCounter,
              subtotal: searchQuery?.type == "cart booking" ? totalPrice : (ticketData?.adultPrice || 0) * filterCounter + addition * filterCounter,
              priceOptionTitle: selectedPriceOption?.text,
              priceOptionId: String(selectedPriceOption?.value),
              addition,
              additional_price_type: ticketData?.additional_price_type,
              additional_price_image: ticketData?.additional_price_image,
              subCategoryId: String(subCategoryId),
              subCategoryTitle: subCategoryTitle,
              subCategoryPrice: Number(selectedPriceOption?.adult_price || 0),
              ticket_type: ticketData?.ticket_type,
              ticket_id: searchQuery?.type == "cart booking" ? String(ticketData?.id) + "+" + String(subitem_id) : String(ticketData?.id),
              subPath,
              adult_child_type: "Adult",
            });
          } else if (selectedAdultChildType === "Child") {
            childInfo.push({
              cartId,
              tour_date: date,
              reservation_id: searchQuery?.type == "cart booking" ? item.reservation_id : null,
              item_id: searchQuery?.type == "cart booking" ? item.item_id : null,
              categoryId: String(categoryId),
              name: ticketData?.name || "",
              price: ticketData?.childPrice || 0,
              quantity: filterCounter,
              subtotal: searchQuery?.type == "cart booking" ? totalPrice : (ticketData?.childPrice || 0) * filterCounter + addition * filterCounter,
              priceOptionTitle: selectedPriceOption?.text,
              priceOptionId: String(selectedPriceOption?.value),
              addition,
              additional_price_type: ticketData?.additional_price_type,
              additional_price_image: ticketData?.additional_price_image,
              subCategoryId: String(subCategoryId),
              subCategoryTitle: subCategoryTitle,
              subCategoryPrice: Number(selectedPriceOption?.child_price || 0),
              ticket_type: ticketData?.ticket_type,
              ticket_id: searchQuery?.type == "cart booking" ? String(ticketData?.id) + "+" + String(subitem_id) : String(ticketData?.id),
              subPath,
              adult_child_type: "Child",
            });
          }
        }
      }

      if (subCategoryId === menu[1].dropdownElements?.[1].id) {
        // City pass page
        if (selectedAdultChildType === "Adult") {
          adultInfo.push({
            cartId,
            reservation_id: null,
            item_id: null,
            cityId: cityId.toString(),
            subCategoryName: "city-pass",
            name: singleTicket?.title_en || "",
            price: packagePriceOptions?.[0]?.adult_price || 0,
            quantity: filterCounter,
            subtotal: (packagePriceOptions?.[0]?.adult_price || 0) * filterCounter,
            addition: 0,
            additional_price_type: singleTicket?.additional_price_type,
            additional_price_image: singleTicket?.additional_price_image,
            ticket_type: singleTicket?.ticket_type,
            ticket_id: String(singleTicket?.id),
            adult_child_type: "Adult",
          });
        } else if (selectedAdultChildType === "Child") {
          childInfo.push({
            cartId,
            reservation_id: null,
            item_id: null,
            cityId: cityId.toString(),
            subCategoryName: "city-pass",
            name: singleTicket?.title_en || "",
            price: packagePriceOptions?.[0]?.child_price || 0,
            quantity: filterCounter,
            subtotal: (packagePriceOptions?.[0]?.child_price || 0) * filterCounter,
            addition: 0,
            additional_price_type: singleTicket?.additional_price_type,
            additional_price_image: singleTicket?.additional_price_image,
            ticket_type: singleTicket?.ticket_type,
            ticket_id: String(singleTicket?.id),
            adult_child_type: "Child",
          });
        }
      } else if (subCategoryId === menu[1].dropdownElements?.[2].id) {
        // Explore view page
        if (selectedAdultChildType === "Adult") {
          adultInfo.push({
            cartId,
            reservation_id: null,
            item_id: null,
            cityId: cityId.toString(),
            subCategoryName: "explore-pass",
            name: selectedPriceOption?.text,
            price: packagePriceOptions?.[0]?.adult_price || 0,
            quantity: filterCounter,
            priceOptionTitle: selectedPriceOption?.text,
            priceOptionId: String(selectedPriceOption?.value),
            subtotal: (packagePriceOptions?.[0]?.adult_price || 0) * filterCounter,
            addition: 0,
            additional_price_type: singleTicket?.additional_price_type,
            additional_price_image: singleTicket?.additional_price_image,
            ticket_type: singleTicket?.ticket_type,
            ticket_id: String(singleTicket?.id),
            adult_child_type: "Adult",
          });
        } else if (selectedAdultChildType === "Child") {
          childInfo.push({
            cartId,
            reservation_id: null,
            item_id: null,
            cityId: cityId.toString(),
            subCategoryName: "explore-pass",
            name: selectedPriceOption?.text,
            price: packagePriceOptions?.[0]?.child_price || 0,
            quantity: filterCounter,
            priceOptionTitle: selectedPriceOption?.text,
            priceOptionId: String(selectedPriceOption?.value),
            subtotal: (packagePriceOptions?.[0]?.child_price || 0) * filterCounter,
            addition: 0,
            additional_price_type: singleTicket?.additional_price_type,
            additional_price_image: singleTicket?.additional_price_image,
            ticket_type: singleTicket?.ticket_type,
            ticket_id: String(singleTicket?.id),
            adult_child_type: "Child",
          });
        }
      }

      if (flg) {
        Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: `Please select a tour schedule to proceed`,
        });
      } else handleUpdateCart(adultInfo, childInfo);
    }
  };

  return !isLoading && editLoading ? (
    <div className="flex flex-col items-center pb-8 bg-white max-h-fit">
      <div className="flex flex-col items-center w-full bg-white">
        <span className="py-8 font-bold font-poppins text-[#5D5D5F]">티켓구입</span>
      </div>
      <div className="flex items-center justify-center w-full p-4 text-xl text-white bg-blue">
        <img alt="" width={18} className="mr-5" src={staticFiles.icons.tag_white} />$
        <TotalPriceCom totalPrice={totalPrice.toFixed(2)} />
      </div>
      <div className="w-[100%] flex flex-col px-8 gap-4">
        <div className="w-full text-base mt-4 text-[#5D5D5F]">Please Select a Package Product</div>
        {subCategoryId !== menu[1].dropdownElements?.[1].id ? (
          <SelectInput
            selected={selectedPriceOptionId}
            setSelected={(val) => {
              setSelectedPriceOptionId(val);
              setMaxLimit(packagePriceOptions?.find((item: any) => item.value === val)?.quantity || 0);
              setSelectedPriceOption(packagePriceOptions?.find((item: any) => item.value === val));
            }}
            options={packagePriceOptions || []}
          />
        ) : (
          <>
            <div className="flex w-2/3 gap-5">
              <div>
                <span className="font-semibold text-base text-[#5D5D5F]">Adult:&nbsp;</span>${""}
                <span className=" text-[#5D5D5F]">  {packagePriceOptions?.[0]?.adult_price}</span>

              </div>{" "}
              <div>
                {" "}
                <span className="font-semibold text-base text-[#5D5D5F]">Child:&nbsp;</span> <span className="text-[#5D5D5F]">${packagePriceOptions?.[0]?.child_price}</span>
              </div>
            </div>{" "}
            {/* <SpaceY /> */}
            <p className="w-full text-start text-[#5D5D5F] text-base">Please choose 5 tickets only</p>
            {/* <SpaceY /> */}
          </>
        )}

        {/* <hr className="w-full border rounded border-gray" /> */}
        {/* <SpaceY /> */}
        <div className="flex w-full justify-between">
          <SelectInput
            selected={selectedAdultChildType}
            setSelected={(val) => setSelectedAdultChildType(val as "Adult" | "Child")}
            containerClassName="w-1/2"
            options={[
              { value: "Adult", text: "Adult" },
              { value: "Child", text: "Child" },
            ]}
          />
          <div className="w-1/2 flex justify-center">
            <div className="flex items-center justify-between w-full px-[15%]">
              <img alt="" className="cursor-pointer" width={20} src={staticFiles.icons.decrement} onClick={() => handleQuantityCounter(filterCounter, "DEC")} />
              <FilterCounterCom filterCounter={filterCounter} />
              <img alt="" className="cursor-pointer" width={20} src={staticFiles.icons.increment} onClick={() => handleQuantityCounter(filterCounter, "INC")} />
            </div>
          </div>
        </div>
        {/* <SpaceY /> */}
        {tour === true ? <span className="text-base text-[#5D5D5F]">Please select tour tickets to proceed</span> : <></>}
        {/* <SpaceY /> */}
        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onRequestClose={handleCloseModal}
            style={{
              overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.5)" },
              content: {
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)", // Centers the modal
                minWidth: "400px",
                height: "70vh",
                borderRadius: 20,
                padding: 0,
              },
            }}
          >
            <MultiTourCalendar
              order={order}
              selectedTour={selectedTour.find((obj: any) => obj.hasOwnProperty(order))}
              handleClick={(date: string, id: string) => {
                handleClick(date, id);
              }}
              setIsOpen={(isOpen: boolean) => {
                setIsModalOpen(isOpen);
              }}
            />
          </Modal>
        )}

        {tickets?.map((i, index) => {
          let canEdit = true;

          if (searchQuery?.edit && (searchQuery?.type == "cart booking" || searchQuery?.type == "booking")) {
            if (i?.ticket_type === "Bar code/QR code") {
              canEdit = true;
              if (item?.tickets.findIndex((it: any) => it.ticket_id == i.id) > 0) {
                if (item?.tickets[item?.tickets.findIndex((it: any) => it.ticket_id == i.id)]?.ticket_sent_status == "sent") {
                  canEdit = false;
                }
              }
            } else if (i?.ticket_type === "Regular") {
              canEdit = true;
              if (item?.tickets.findIndex((it: any) => it.ticket_id == i.id) > 0) {
                if (item?.tickets[item?.tickets.findIndex((it: any) => it.ticket_id == i.id)]?.ticket_sent_status == "sent") {
                  canEdit = false;
                }
              }
              if (item?.tickets.findIndex((it: any) => it.ticket_id == i.id) > 0) {
                if (item?.tickets[item?.tickets.findIndex((it: any) => it.ticket_id == i.id)]?.ticket_sent_status == "pending") {
                  canEdit = false;
                }
              }
            } else {
              //i.ticket_type === "Guide Tour", and other types..
              canEdit = true;

              if (searchQuery?.type == "cart booking") {
                let reservations = reservationsParsedData.reservations;
                let currentReservation: any = reservations[reservations.findIndex((it: any) => it.reservation_id === item.reservation_id)];
                let currentItemBeforeUpdate: any = currentReservation?.items.filter((it: any) => it.item_id == item.item_id);

                if (currentItemBeforeUpdate && currentItemBeforeUpdate[0]?.tickets.findIndex((it: any) => it.ticket_id == i.id.toString()) > -1) {
                  canEdit = false;
                }
              } else {
                canEdit = true;
                if (item?.tickets.findIndex((it: any) => it.ticket_id == i.id) > -1) {
                  canEdit = false;
                }
              }
            }
          }
          if (item?.tickets.find((ticket: any) => ticket.ticket_id === i.id)?.ticket_type === "Guide Tour") {
            canEdit = false;
          }
          return (
            <IncludesInfo
              onClick={() => {
                setSelectedTicketIds((prev) =>
                  prev.includes(i.id) && !noneditableTicketIds.includes(i.id) && !(item?.tickets.find((ticket: any) => ticket.ticket_id === i.id)?.ticket_type === "Guide Tour")
                    ? prev.filter((i2) => i2 !== i.id)
                    : prev.length >= maxLimit
                      ? prev
                      : [...prev, i.id]
                );
              }}
              setIsOpenModal={setIsModalOpen}
              key={i.id}
              index={Number(i.id)}
              setOrder={setOrder}
              canEdit={canEdit}
              isIncluded={selectedTicketIds.includes(i.id)}
              isSelected={selectedTour.some((obj: any) => obj.hasOwnProperty(i.id))}
              name={i.name}
              hasDate={i.ticket_type === "Guide Tour" ? true : false}
              hasGoldStar={i.isPremium}
              premiumType={i.additional_price_type || ""}
            />
          );
        })}

        {/* <SpaceY /> */}

        <div className="flex w-full gap-x-1">
          <MainButton onClick={() => handleAddToTheCart()} text="Add to Cart" />
          {/* <SecondaryButton onClick={handleReset} text="Reset" /> */}
        </div>
      </div>
    </div>
  ) : (
    <div>loading...</div>
  );
};

const TotalPriceCom = ({ totalPrice }: any) => {
  return <div>{totalPrice}</div>;
};

const FilterCounterCom = ({ filterCounter }: any) => {
  return <div>{filterCounter}</div>;
};

export const TicketSelector = ({ ticket }: { ticket: any }) => {
  const [cart, setCart] = cartState.useState();
  const { search } = useLocation(); // ?edit=true&cartId=1690069215841
  const location = useLocation();
  const navigate = useNavigate();
  const item = location.state;

  const [filterCounter, setFilterCounter] = useState(1); // user selected ticket quantity.
  const [selectedAdultChildType, setSelectedAdultChildType] = useState<"Adult" | "Child">("Adult"); // adult or child
  const [ticketPrice, setTicketPrice] = useState(0);
  const [bookingDate, setBookingDate] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [selectedTour, setSelectedTour] = useState<string>();
  const [cartBookingCount, setCartBookingCount] = useState(0);
  const [reservationsData, setReservationsData] = reservationsState.useState();

  const searchQuery = extractParams("http://localhost:3000" + search);

  const handleQuantityCounter = (prev: number, action: "INC" | "DEC") => {
    if (action === "DEC" && prev === 0) {
      toast.warning("Quantity must be 1 or higher");
      return;
    }

    if (action === "DEC") return setFilterCounter(prev - 1);
    if (action === "INC") return setFilterCounter(prev + 1);
  };
  useEffect(() => {
    if (search) {
      const searchQuery = extractParams("http://localhost:3000" + search);
      if ((cart.adultInfo.length > 0 || cart.childInfo.length > 0) && searchQuery?.edit && searchQuery?.type === "cart") {
        let cartData = localStorage.getItem("CART_DATA") ? JSON.parse(localStorage.getItem("CART_DATA") || "") : { adultInfo: [], childInfo: [] };

        const { adultInfo, childInfo }: cartData = cart;
        const cartItemsToEdit = [...adultInfo, ...childInfo]?.filter((obj) => obj?.cartId === searchQuery?.cartId);
        setFilterCounter(cartItemsToEdit[0].quantity);
        setSelectedAdultChildType(cartItemsToEdit[0]?.adult_child_type || "Adult");
      }

      if (searchQuery?.edit && searchQuery?.type == "booking") {
        setFilterCounter(Number(item.quantity));
        setSelectedAdultChildType(item.adult_child_type);
      }

      if ((cart.adultInfo.length > 0 || cart.childInfo.length > 0) && searchQuery?.edit && searchQuery?.type == "cart booking") {
        setFilterCounter(Number(item?.quantity));
        setSelectedAdultChildType(item?.adult_child_type);
      }
    }
  }, [search]);

  useEffect(() => {
    let addition = 0;
    if (ticket?.additional_price_type === "Premium") {
      addition = Number(ticket?.premium_amount);
    } else if (ticket?.additional_price_type === "Premium S") {
      addition = Number(ticket?.premium_s_amount);
    }

    let ticketBasePrice = 0;
    if (selectedAdultChildType === "Adult") {
      ticketBasePrice = Number(ticket?.ticket_prices?.filter((option: any) => option.type === "Adult")[0]?.sale_price);
    } else if (selectedAdultChildType === "Child") {
      ticketBasePrice = Number(ticket?.ticket_prices?.filter((option: any) => option.type === "Child")[0]?.sale_price);
    }

    if (ticket?.ticket_type === "SIM card") {
      ticketBasePrice = ticket?.ticket_prices[0]?.sale_price
    }
 
    let ticketPrice = (addition + ticketBasePrice) * filterCounter;
    setTicketPrice(ticketPrice);
  }, [ticket, filterCounter, selectedAdultChildType]);

  useEffect(() => {
    return () => {
      setFilterCounter(1);
      setSelectedAdultChildType("Adult");
      Swal.close();
    };
  }, []);

  const handleReset = () => {
    setFilterCounter(1);
    setSelectedAdultChildType("Adult");
  };

  const handleUpdateCart = (adultOrder: any, childOrder: any) => {
    let isEditItems = checkIsEditItems(cart);
    if (isEditItems && searchQuery?.type !== "cart booking" && searchQuery?.type !== "booking") {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "You have Booking Edit Items, please check Shopping Cart first!",
      });
    } else {
      let isEdited = checkDoubleBookingEdit(cart, item?.reservation_id);

      if (searchQuery?.type === "cart booking" && cartBookingCount === 0) {
        isEdited = false;
        setCartBookingCount(1);
      }

      if (item && ticketPrice < item?.total) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Downgrading is not allowed!",
        });
      } else if (item && ticketPrice == item?.total && searchQuery?.type != "cart booking") {
        Swal.fire({
          title: "Do you want to update?",
          text: "You have no balance to pay and the change will be updated in My Bookings.",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "OK",
          cancelButtonText: "No",
        }).then(async (result) => {
          if (result.isConfirmed) {
            Swal.fire({
              icon: "info",
              title: "Updating now...",
              text: "Please Wait!",
              showConfirmButton: false,
              confirmButtonText: "",
            });

            const token = localStorage.getItem("authToken");
            const orderLoginData = localStorage.getItem("orderLoginNumber");
            const orderLoginEmail = localStorage.getItem("orderLoginEmail");

            if (token || orderLoginData) {
              let items: any = [];
              let reservations = reservationsData.reservations;

              let currentReservation: any = reservations[reservations.findIndex((it: any) => it.id === item.reservation_id)];

              let currentItemBeforeUpdate: any = currentReservation?.reservation_items.filter((it: any) => it.id == item.item_id);

              let otherItems = currentReservation.reservation_items.filter((it: any) => it.id != item.item_id);

              let otherItemsTosend = otherItems.map((data: any) => ({
                id: data.id,
                adult_child_type: data.adult_child_type,
                child_age: data.child_age,
                price: parseFloat(data.price) || 0,
                quantity: parseInt(data.quantity),
                ticket_sent_status: data.ticket_sent_status,
                ticket_sent_date: data.ticket_sent_date,
                refund_status: data.refund_status,
                refund_sent_date: data.refund_sent_date,
                reservation_id: data.reservation_id,
                category_id: data.category_id,
                subcategory_id: data.subcategory_id,
                price_list_id: data.price_list_id,
                sub_items:
                  data.reservation_sub_items?.map((subitem: any) => ({
                    id: subitem.id,
                    rq_schedule_datetime: subitem.rq_schedule_datetime,
                    ticket_id: subitem.ticket_id,
                    refund_status: subitem.refund_status,
                  })) || [],
              }));

              items.push(...otherItemsTosend);
              let currentItem: any = {
                id: item.item_id,
                adult_child_type: selectedAdultChildType,
                child_age: item.child_age,
                price: ticket?.ticket_prices[0].sale_price || 0,
                quantity: filterCounter,
                ticket_sent_status: null,
                ticket_sent_date: null,
                refund_status: null,
                refund_sent_date: null,
                reservation_id: item.reservation_id,
                category_id: currentItemBeforeUpdate[0].category_id,
                subcategory_id: item.subcategory_id,
                price_list_id: null,
                sub_items: [
                  {
                    id: item.tickets[0].subitem_id,
                    rq_schedule_datetime: null,
                    ticket_id: String(ticket?.id),
                    refund_status: false,
                  },
                ],
              };

              items.push(currentItem);

              try {
                const result = await axios.put(`https://travelapi.unifyq.com/tickets-api-new/public/api/reservations/user-create/${item.reservation_id}`, {
                  items,
                  stripe_token: null,
                });
                if (result.status === 200) {
                  await Swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: "My Bookings will have the update. Please check.",
                    showConfirmButton: true,
                    confirmButtonText: "Go to My Bookings",
                  });

                  if (token) {
                    navigate("/my-bookings");
                  } else {
                    navigate(`/my-bookings?email=${orderLoginEmail}&order_number=${orderLoginData}`);
                  }
                } else {
                  Swal.fire({
                    icon: "warning",
                    title: "Something wrong!",
                    text: "Please try again!",
                    confirmButtonText: "OK",
                  });
                }
              } catch (error) {
                Swal.fire({
                  icon: "warning",
                  title: "Something wrong!",
                  text: "Please try again!",
                  confirmButtonText: "OK",
                });
              }
            }
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            return;
          }
        });
      } else if (isEdited) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You already Edited, please check Shopping Cart!",
        });
      } else {
        let rawData: any = localStorage.getItem("CART_DATA");
        if (!rawData) {
          rawData = JSON.stringify({
            adultInfo: [],
            childInfo: [],
          });
        }
        if (rawData) {
          const { adultInfo, childInfo }: cartData = JSON.parse(rawData);

          const searchQuery = extractParams("http://localhost:3000" + search);
          let adultInfoToConcat = adultInfo;
          let childInfoToConcat = childInfo;
          if (searchQuery?.edit && (searchQuery?.type === "cart" || searchQuery?.type === "cart booking")) {
            adultInfoToConcat = adultInfoToConcat.filter((ticket) => ticket.cartId !== searchQuery?.cartId);
            childInfoToConcat = childInfoToConcat.filter((ticket) => ticket.cartId !== searchQuery?.cartId);
          }

          localStorage.setItem(
            "CART_DATA",
            JSON.stringify({
              adultInfo: adultInfoToConcat.concat(adultOrder),
              childInfo: childInfoToConcat.concat(childOrder),
            })
          );

          setCart({
            adultInfo: adultInfoToConcat.concat(adultOrder),
            childInfo: childInfoToConcat.concat(childOrder),
          });

          if (searchQuery?.edit && (searchQuery?.type == "cart" || searchQuery?.type == "cart booking")) {
            toast.info("You items has been successfully edited!");
          } else {
            toast.success("Success! Items have been added to the cart!");
          }
        }
      }
    }
  };

  const handleButtonClick = (event: any) => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);

    // handleCart({ bookingDate });
  };

  const handleCart = async ({ bookingDate }: any) => {
    const childInfo: CartItem[] = [...cart.childInfo];
    const adultInfo: CartItem[] = [...cart.adultInfo];

    if (selectedAdultChildType === "Adult") {
      adultInfo.push({
        name: ticket?.title_en || "",
        price: ticket?.ticket_prices[0].sale_price || 0,
        quantity: filterCounter,
        ...(bookingDate && {
          includes: [
            {
              medal: MedalEnum["GOLD"],
              name: ticket?.title_en || "",
              scheduledDate: moment(bookingDate).format("LLL"),
            },
          ],
        }),
        subtotal: (ticket?.ticket_prices[0].sale_price || 0) * filterCounter,
        addition: 0,
      });
    } else if (selectedAdultChildType === "Child") {
      childInfo.push({
        name: ticket?.title_en || "",
        price: ticket?.ticket_prices[1].sale_price || 0,
        quantity: filterCounter,
        subtotal: (ticket?.ticket_prices[1].sale_price || 0) * filterCounter,
        ...(bookingDate && {
          includes: [
            {
              medal: MedalEnum["SILVER"],
              name: ticket?.title_en || "",
              scheduledDate: moment(bookingDate).format("LLL"),
            },
          ],
        }),

        addition: 0,
      });
    }
    setBookingDate(null);
    localStorage.setItem(
      "CART_DATA",
      JSON.stringify({
        adultInfo,
        childInfo,
      })
    );
    toast.success("Success! Items have been added to the cart!");
    setCart({
      adultInfo,
      childInfo,
    });
  };

  const handleClick = (date: string) => {
    setSelectedTour(date);
  };

  interface TimePickerState {
    selectedTime: string;
  }

  const [stateTime, setTimeState] = useState<TimePickerState>({
    selectedTime: "",
  });

  const handleTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTimeState({ selectedTime: event.target.value });
  };

  return (
    <div className="flex flex-col items-center pb-4 bg-white max-h-fit">
      <div className="flex flex-col items-center w-full bg-white">
        <span className="py-5 font-bold font-poppins">티켓구입</span>
      </div>
      <div className="flex items-center justify-center w-full p-4 text-xl text-white bg-blue">
        <img width={18} className="mr-5" src={staticFiles.icons.tag_white} />$
        <TotalPriceCom totalPrice={ticketPrice} />
      </div>
      <div className="w-[100%] flex flex-col px-8">
        {/* <hr className="w-full border rounded border-gray" /> */}
        {/* <SpaceY /> */}
        <div className="flex w-full justify-center my-4">
          {
            ticket?.ticket_type === "SIM card" ?
              <p>수량 선택</p> :
              ticket?.ticket_prices[1]?.sale_price != 0 ? <SelectInput
                selected={selectedAdultChildType}
                setSelected={(val) => setSelectedAdultChildType(val as "Adult" | "Child")}
                containerClassName="w-1/2"
                options={[
                  { value: "Adult", text: "Adult" },
                  { value: "Child", text: "Child" },
                ]}
              /> :
              <p>Adult</p>
          }
          <div className="w-1/2 flex justify-center">
            <div className="flex items-center justify-between w-full px-[15%]">
              <img alt="" className="cursor-pointer" width={20} src={staticFiles.icons.decrement} onClick={() => handleQuantityCounter(filterCounter, "DEC")} />
              <FilterCounterCom filterCounter={filterCounter} />
              <img alt="" className="cursor-pointer" width={20} src={staticFiles.icons.increment} onClick={() => handleQuantityCounter(filterCounter, "INC")} />
            </div>

          </div>

        </div>
        {/* <SpaceY /> */}
        <div className="flex items-center justify-center w-full mb-4 gap-x-4">
          <span onClick={handleButtonClick}>
            {ticket?.ticket_type === "Guide Tour" ? <img className=" mr-1" alt="" width={20} src={!selectedTour ? staticFiles.icons.calendar : staticFiles.icons.greenCalendar} /> : <></>}
          </span>
          <span>{ticket?.ticket_type === "Guide Tour" ? <span className=" w-full text-center">Please select a tour schedule</span> : <></>}</span>
        </div>

        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onRequestClose={handleCloseModal}
            style={{
              overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.5)" },
              content: {
                top: 100,
                left: 500,
                width: 750,
                height: 676,
                borderRadius: 20,
                padding: 0,
              },
            }}
          >
            <TourCalendar
              ticket={ticket}
              selectedTour={selectedTour}
              handleClick={(date: string) => {
                handleClick(date);
              }}
              setIsOpen={(isOpen: boolean) => {
                setIsModalOpen(isOpen);
              }}
            />
          </Modal>
        )}
        {/* <SpaceY /> */}
        <div className="flex w-full gap-x-1">
          <MainButton
            onClick={() => {
              const childInfo: CartItem[] = [];
              const adultInfo: CartItem[] = [];

              let cartId = String(Date.now());
              const searchQuery = extractParams("http://localhost:3000" + search);
              if (searchQuery?.edit && (searchQuery?.type == "cart" || searchQuery?.type == "cart booking")) {
                cartId = searchQuery?.cartId || String(Date.now());
              }

              let addition = 0;
              if (ticket?.additional_price_type === "Premium") {
                addition = Number(ticket?.premium_amount || 0);
              } else if (ticket?.additional_price_type === "Premium S") {
                addition = Number(ticket?.premium_s_amount || 0);
              }

              if (searchQuery?.edit && searchQuery?.type === "booking") {
                let reservations = reservationsData.reservations;

                let currentReservation: any = reservations[reservations.findIndex((it: any) => it.id === item.reservation_id)];

                let currentItemBeforeUpdate: any = currentReservation?.reservation_items.filter((it: any) => it.id == item.item_id);

                if (selectedAdultChildType === "Adult") {
                  adultInfo.push({
                    cartId: String(Number(cartId) - 1),
                    tour_date: selectedTour,
                    reservation_id: item.reservation_id,
                    item_id: item.item_id,
                    name: ticket?.title_en || "",
                    price: ticket?.ticket_prices[0].sale_price || 0,
                    quantity: Number(item?.quantity),
                    subtotal: -100,
                    addition,
                    additional_price_type: ticket?.additional_price_type,
                    additional_price_image: ticket?.additional_price_image,
                    categoryId: currentItemBeforeUpdate[0].category_id,
                    subCategoryPrice: ticket?.ticket_prices[0].sale_price || 0,
                    subCategoryId: item.subcategory_id,
                    ticket_type: ticket?.ticket_type,
                    ticket_id: String(ticket?.id),
                    adult_child_type: "Adult",
                  });

                  adultInfo.push({
                    cartId,
                    tour_date: selectedTour,
                    reservation_id: item.reservation_id,
                    item_id: item.item_id,
                    name: ticket?.title_en || "",
                    price: ticket?.ticket_prices[0].sale_price || 0,
                    quantity: filterCounter,
                    subtotal: ticketPrice,
                    addition,
                    additional_price_type: ticket?.additional_price_type,
                    additional_price_image: ticket?.additional_price_image,
                    categoryId: currentItemBeforeUpdate[0].category_id,
                    subCategoryId: item.subcategory_id,
                    subCategoryPrice: ticket?.ticket_prices[0].sale_price || 0,
                    ticket_type: ticket?.ticket_type,
                    ticket_id: String(ticket?.id),
                    adult_child_type: "Adult",
                  });
                } else if (selectedAdultChildType === "Child") {
                  childInfo.push({
                    cartId: String(Number(cartId) - 1),
                    tour_date: selectedTour,
                    reservation_id: item.reservation_id,
                    item_id: item.item_id,
                    name: ticket?.title_en || "",
                    price: ticket?.ticket_prices[1].sale_price || 0,
                    quantity: Number(item?.quantity),
                    subtotal: -100,
                    addition,
                    additional_price_type: ticket?.additional_price_type,
                    additional_price_image: ticket?.additional_price_image,
                    categoryId: currentItemBeforeUpdate[0].category_id,
                    subCategoryId: item.subcategory_id,
                    ticket_type: ticket?.ticket_type,
                    ticket_id: String(ticket?.id),
                    adult_child_type: "Child",
                  });

                  childInfo.push({
                    cartId,
                    tour_date: selectedTour,
                    reservation_id: item.reservation_id,
                    item_id: item.item_id,
                    name: ticket?.title_en || "",
                    price: ticket?.ticket_prices[1].sale_price || 0,
                    quantity: filterCounter,
                    subtotal: ticketPrice,
                    addition,
                    additional_price_type: ticket?.additional_price_type,
                    additional_price_image: ticket?.additional_price_image,
                    categoryId: currentItemBeforeUpdate[0].category_id,
                    subCategoryId: item.subcategory_id,
                    ticket_type: ticket?.ticket_type,
                    ticket_id: String(ticket?.id),
                    adult_child_type: "Child",
                  });
                }
              } else if (searchQuery?.edit && searchQuery?.type === "cart booking") {
                let reservations = reservationsData.reservations;
                let currentReservation: any = reservations[reservations.findIndex((it: any) => it.id === item.reservation_id)];
                let currentItemBeforeUpdate: any = currentReservation?.reservation_items.filter((it: any) => it.id == item.item_id);

                if (selectedAdultChildType === "Adult") {
                  adultInfo.push({
                    cartId,
                    tour_date: selectedTour,
                    reservation_id: item.reservation_id,
                    item_id: item.item_id,
                    categoryId: currentItemBeforeUpdate[0].category_id,
                    name: ticket?.title_en || "",
                    price: ticket?.ticket_prices[0].sale_price || 0,
                    subCategoryPrice: ticket?.ticket_prices[0].sale_price || 0,
                    quantity: filterCounter,
                    subtotal: (ticket?.ticket_prices[0].sale_price || 0) * filterCounter + addition * filterCounter,
                    addition,
                    additional_price_type: ticket?.additional_price_type,
                    additional_price_image: ticket?.additional_price_image,
                    ticket_type: ticket?.ticket_type,
                    ticket_id: String(ticket?.id),
                    adult_child_type: "Adult",
                  });
                } else if (selectedAdultChildType === "Child") {
                  childInfo.push({
                    cartId,
                    tour_date: selectedTour,
                    reservation_id: item.reservation_id,
                    item_id: item.item_id,
                    categoryId: currentItemBeforeUpdate[0].category_id,
                    name: ticket?.title_en || "",
                    price: ticket?.ticket_prices[1].sale_price || 0,
                    subCategoryPrice: ticket?.ticket_prices[1].sale_price || 0,
                    quantity: filterCounter,
                    subtotal: (ticket?.ticket_prices[1].sale_price || 0) * filterCounter + addition * filterCounter,
                    addition,
                    additional_price_type: ticket?.additional_price_type,
                    additional_price_image: ticket?.additional_price_image,
                    ticket_type: ticket?.ticket_type,
                    ticket_id: String(ticket?.id),
                    adult_child_type: "Child",
                  });
                }
              } else {
                if (selectedAdultChildType === "Adult") {
                  adultInfo.push({
                    cartId,
                    tour_date: selectedTour,
                    reservation_id: null,
                    item_id: null,
                    name: ticket?.title_en || "",
                    price: ticket?.ticket_prices[0].sale_price || 0,
                    quantity: filterCounter,
                    subtotal: (ticket?.ticket_prices[0].sale_price || 0) * filterCounter + addition * filterCounter,
                    addition,
                    additional_price_type: ticket?.additional_price_type,
                    additional_price_image: ticket?.additional_price_image,
                    ticket_type: ticket?.ticket_type,
                    ticket_id: String(ticket?.id),
                    adult_child_type: "Adult",
                  });
                } else if (selectedAdultChildType === "Child") {
                  childInfo.push({
                    cartId,
                    tour_date: selectedTour,
                    reservation_id: null,
                    item_id: null,
                    name: ticket?.title_en || "",
                    price: ticket?.ticket_prices[1].sale_price || 0,
                    quantity: filterCounter,
                    subtotal: (ticket?.ticket_prices[1].sale_price || 0) * filterCounter + addition * filterCounter,
                    addition,
                    additional_price_type: ticket?.additional_price_type,
                    additional_price_image: ticket?.additional_price_image,
                    ticket_type: ticket?.ticket_type,
                    ticket_id: String(ticket?.id),
                    adult_child_type: "Child",
                  });
                }
              }
              if (!selectedTour && ticket.ticket_type === "Guide Tour") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Please select a tour schedule!",
                });
                return;
              } else handleUpdateCart(adultInfo, childInfo);
            }}
            text="Add to Cart"
          />
          {/* <SecondaryButton onClick={handleReset} text="Reset" /> */}
        </div>
      </div>
    </div>
  );
};

const DateRangePickerComponent = ({ bookingDate, setBookingDate, tickets }: any) => {
  const [selectedDate, setSelectedDate] = useState<any>(null);

  const handleDateChange = (event: any) => {
    setSelectedDate(event.value);
  };

  const formatTime = (time: any) => {
    // Assuming that the time format in the data is "HH:mm:ss"
    const [hours, minutes, seconds] = time.split(":");
    return new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), hours, minutes, seconds);
  };

  const handleBooking = (time: any) => {
    const selectedTime = formatTime(time);
    const combinedDateTime = new Date(selectedDate);
    combinedDateTime.setHours(selectedTime.getHours());
    combinedDateTime.setMinutes(selectedTime.getMinutes());
    combinedDateTime.setSeconds(selectedTime.getSeconds());
    setBookingDate(combinedDateTime);
  };

  const filteredTimeSlots = tickets?.filter((item: any) => {
    const date = new Date(item.date_start);
    const endDate = new Date(item.date_end);
    return selectedDate >= date && selectedDate <= endDate && item.week_days.includes(selectedDate.toLocaleString("en-us", { weekday: "long" }));
  });

  return (
    <div>
      <Calendar onChange={handleDateChange} />
      {filteredTimeSlots?.map((slot: any) => (
        <div key={slot.id} className="k-button k-mb-4">
          <p onClick={() => handleBooking(slot.time)}> {slot.time}</p>
        </div>
      ))}
    </div>
  );
};

const RangeCalc: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const availableDates = [new Date(2023, 6, 1), new Date(2023, 6, 4), new Date(2023, 6, 5), new Date(2023, 6, 9), new Date(2023, 6, 23), new Date(2023, 6, 25)];

  const disabledDates = (date: Date) => {
    return !availableDates.some((availableDate) => isSameDay(availableDate, date));
  };

  return (
    <div>
      <DatePicker
      // selected={selectedDate}
      // onChange={handleDateChange}
      // filterDate={disabledDates}
      // dateFormat="dd/MM/yyyy"
      // placeholderText="Select date"
      />
    </div>
  );
};
