import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import "./styles.css";
import "react-phone-number-input/style.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./shared/routes";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import { createState } from "state-pool";
import { MedalEnum } from "./modules/cart/components/CardInfo";
import { useCacheCart, useCityState, useMusicState } from "./shared/hooks";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavBarElement } from "./shared/components/NavBar";
import { useEffect } from "react";
import { API } from "./shared";

const queryClient = new QueryClient();

export const authState = createState(localStorage.getItem("AUTH_TOKEN"));
export const musicState = createState<{
  music_id: string;
}>({
  music_id: "",
});

export type CartItem = {
  cartId: string;
  tour_date?: string;
  reservation_id: number | null;
  item_id: number | null;
  adult_child_type: "Adult" | "Child";
  name: string;
  price: number;
  priceOptionTitle?: string;
  priceOptionId?: string;
  quantity: number;
  addition: number;
  subtotal: number;
  additional_price_type?: string;
  additional_price_image?: string;
  subCategoryName?: string;
  cityId?: string;
  categoryId?: string;
  subCategoryId?: string;
  subCategoryTitle?: string;
  subCategoryPrice?: number;
  subPath?: string;
  ticket_type?: string;
  ticket_id?: string;
  includes?: {
    medal: MedalEnum;
    name: string;
    addition?: number;
    scheduledDate?: string;
  }[];
};

export const cityState = createState<{
  city_id: string;
  cityName: string;
}>({
  city_id: "1",
  cityName: "New York",
});
const pageNavBar: NavBarElement[] = [
  {
    name: "Home",
    path: "/main",
  },
  {
    name: "NY Package Tour",
    path: "/package-tour",
    dropdownElements: [
      { name: "NY Big Apple Pass", subPath: "ba-pass", id: 97, category_id: 1 },
      { name: "NY City Pass", subPath: "city-pass", id: 98, category_id: 1 },
      { name: "NY City Explore Pass", subPath: "explore-pass", id: 99, category_id: 1 },
    ],
  },
  {
    name: "NY City Attractions",
    path: "/city-attractions",
    dropdownElements: [
      { name: "NY Observation(Scenics)", subPath: "observations", id: 2, category_id: 4 },
      { name: "NY Museum/Gallery", subPath: "museum-gallery", id: 104, category_id: 4 },
      { name: "NY Rides/Cruises", subPath: "rides-cruises", id: 105, category_id: 4 },
      { name: "NY Activities", subPath: "activities", id: 106, category_id: 4 },
    ],
  },
  {
    name: "NY Guide Tour",
    path: "/guide-tour",
    dropdownElements: [
      { name: "NY Manhattan Day Tour", subPath: "manhattan-day", id: 4, category_id: 2 },
      { name: "NY Manhattan Night Tour", subPath: "manhattan-night", id: 101, category_id: 2 },
      { name: "NY Doson Tour", subPath: "doson-tour", id: 102, category_id: 2 },
      { name: "NY UN Tour", subPath: "un-tour", id: 103, category_id: 2 },
    ],
  },
  {
    name: "Musicals & Shows",
    path: "/musicals_view",
    dropdownElements: [{ name: "Musicals & Shows", subPath: "", id: 189, category_id: 57 }],
  },
  {
    name: "여행정보",
    path: "/trip-info",
  },
  {
    name: "About Us",
    path: "/about",
  },
  {
    name: "Contact Us",
    path: "/contact",
  },
];

export const menuState = createState<
  {
    path: string;
    name: string;
    dropdownElements?: { name: string; subPath: string; id?: number; category_id?: number }[];
  }[]
>(pageNavBar);

export const cartState = createState<{
  childInfo: CartItem[];
  adultInfo: CartItem[];
}>({
  childInfo: [
    // {
    //   name: "Big Apple 2",
    //   price: 100,
    //   quantity: 1,
    //   addition: 27,
    //   subtotal: 137,
    //   includes: [
    //     {
    //       medal: MedalEnum.GOLD,
    //       name: "Summit Observation Deck",
    //       addition: 18,
    //     },
    //     {
    //       medal: MedalEnum.SILVER,
    //       name: "Moma Museum Doson Tour",
    //       scheduledDate: "01/25/2023 (10:30 AM)",
    //       addition: 9,
    //     },
    //   ],
    // },
  ],
  adultInfo: [],
});

export const reservationsState = createState<{
  reservations: unknown[];
}>({
  reservations: [],
});
export const reservationsParsedState = createState<{
  reservations: unknown[];
}>({
  reservations: [],
});

export const cityDataState = createState<{
  cityData: any[];
}>({
  cityData: [],
});

export const orderLookupState = createState<{
  orderLoginNumber: string;
  orderLoginEmail: string;
  phone: string;
  customer_name_kr: string;
  customer_name_en: string;
}>({
  orderLoginNumber: "",
  orderLoginEmail: "",
  phone: "",
  customer_name_kr: "",
  customer_name_en: "",
});

export const pageNumState = createState<number>(5);

export const cityIdState = createState<number>(Number(localStorage.getItem("cityId")) || 1);

function App() {
  const [cityId] = cityIdState.useState();
  const [menu, setMenu] = menuState.useState();
  useEffect(() => {
    fetch(`${API}/categories?city_id=${Number(cityId)}`)
      .then((res) => res.json())
      .then((data) => {
        let datas = data;
        let menuData = JSON.parse(JSON.stringify(menu));
        datas.forEach((obj: any, index: number) => {
          if (cityId === 36 && index === 4) return;
          if (obj.subcategories && obj.subcategories.length !== 0) {
            obj.subcategories.forEach((item: any, idx: number) => {
              if (!!menuData) {
                let dEle = menuData[index + 1].dropdownElements;
                if (!!dEle) {
                  dEle[idx].name = item.name;
                  dEle[idx].id = Number(item.id);
                  dEle[idx].category_id = Number(item.category_id);
                }
              }
            });
          }
          if (!!menuData) {
            menuData[index + 1].name = obj.name;
          }
        });
        setMenu(menuData);
        localStorage.setItem("cityId", String(cityId));
      })
      .catch((error) => {
        console.log("Error in App.jsx for fetching:", error);
      });
  }, [cityId]);
  useCacheCart();
  useCityState();
  useMusicState();
  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
