import { Controller, useForm } from "react-hook-form";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { useNavigate } from "react-router-dom";
import { MainButton } from "../../shared/components/Buttons";
import { MainInput } from "../../shared/components/Inputs";
import { SpaceY } from "../../shared/components/Utils";
import { NonMemberLoginValidator } from "./utils/validations";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { staticFiles } from "../../shared";
import { API } from "../../shared";
import { orderLookupState } from "../../App";
const resolver = classValidatorResolver(NonMemberLoginValidator);

export const NonMemberLogInView = () => {
  const navigate = useNavigate();
  const [orderLookup, setOrderLookup] = orderLookupState.useState();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      order_number: "",
    },
    resolver,
  });

  const onSubmit = async (data: { email: string; order_number: string }) => {
    const { email, order_number } = data;
    axios
      .get(`${API}/order-lookup?email=${email}&order_number=${order_number}`)
      .then(({ data }) => {
        // toast.success("Non-Member Login Successful!");
        localStorage.setItem("orderLoginNumber", order_number);
        localStorage.setItem("orderLoginEmail", email);

        setOrderLookup({ ...orderLookup, orderLoginNumber: order_number, orderLoginEmail: email });

        navigate(`/my-bookings?email=${email}&order_number=${order_number}`);
      })
      .catch((error) => {
        toast.error("Non-Member Login Failed. Please try again.");
      });
  };

  return (
    <div className="min-h-[600px] flex flex-col items-center pt-[5vh] pb-[20vh]">
      <SpaceY />
      <span className="w-[300px] text-2xl font-bold font-volkhov text-dark">나의 예약상품 조회</span>
      <div className="w-[300px] py-4 text-sm font-normal font-poppins">
        번거로운 로그인 기능. 패스워드 필요 없이 빠르고 간편하게 고객님의 구매정보를 확인하실 수 있습니다. 상품 구매 시 입력한 고객님의 이메일 주소와 상품 구매 후 받으신 주문번호를 이용하여 귑고
        간단하게 조회하세요.{" "}
      </div>
      <SpaceY /> <SpaceY />
      <Controller
        name="email"
        rules={{ required: true }}
        control={control}
        render={({ field }) => <MainInput value={field.value} error={errors.email?.message} placeholder="Email" onChange={(text) => field.onChange(text)} />}
      />
      <SpaceY />
      <Controller
        name="order_number"
        rules={{ required: true }}
        control={control}
        render={({ field }) => <MainInput value={field.value} error={errors.order_number?.message} placeholder="Order Number (주문번호)" onChange={(text) => field.onChange(text)} />}
      />
      <SpaceY />
      <div className="w-[300px]">
      <MainButton text="Order Look Up" onClick={handleSubmit(onSubmit)} />
      </div>

      {/* <img src={staticFiles.icons.warning} /> */}
      <div className="p-6 text-xs font-poppins ">조회에 어려움이 있으시다면 타마스로 문의해 주십시요.</div>
    </div>
  );
};
